import React, { Component } from "react";
import {
  withStyles,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  InputLabel,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
  Paper,
} from "@material-ui/core";
import PreviousExamination from "./PreviousExamination";
import BestOfThree from "./BestOfThree";
import SubjectsCombo from "./SubjectsCombo";
import { blue, lightGreen, orange, green } from "@material-ui/core/colors";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import FileUpload from "./FileUpload";
import AlertDiv from "./AlertDiv";
import { GetData } from "../../../api/service";
import Grade from "@material-ui/icons/Grade";
import Sel from "react-select";
import MultiSubject from "./MultiSubject";

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});

class EducationalForm extends Component {
  state = {
    course: "",
    stream: "",
    course_type: "",
    njc_student: "",
    email: this.props.user.email,
    exam_passed: [],
    best_subjects: [],
    last_marks: [],
    total_marks: "",
    marks_obtained: "",
    percentage: "",

    errors: [],
    subject_list: [],
    f_subject_list: [],
    optional_subjects: [],
    rc_list: [],
    f_rc_list: [],
    aec_list: [],
    sec_list: [],
    major_1: {},
    major_1_disabled: false,
    major_2: {},
    major_2_disabled: false,
    major_3: {},
    major_3_disabled: false,
    compulsory_1: {},
    compulsory_2: {},
    compulsory_3: {},
    generic_1: {},
    generic_2: {},
    generic_3: {},
    mil: {},
    comp_1: {},
    compulsory_list: [],
    generic_list: [],
    mil_list: [],
    mdc_list: [],
    f_mdc_list: [],
    vac_list: [],
    major_list: [],
    f_major_list: [],
    f_generic_list: [],
    subject_comb: "",
    isLoaded: false,
    isDisabled: false,
    has_gap: "",
    gap_admission: "",
    gap_course: "",
    gap_institute: "",
    gap_reason: "",
    gap_roll_no: "",
  };

  __getSubjects = () => {
    GetData(
      `/${this.state.course}/${this.state.stream}/getsubjectcombinationbycourse`
    ).then((resp) => {
      //console.log(resp)
      this.setState({
        subject_list: resp,
      });
    });
  };

  componentDidMount() {
    let data = this.props.data;

    if (Array.isArray(data) && data.length > 0) {
      this.setState(
        {
          ...data[0],
          course:
            data[0].course === "" || data[0].course === null
              ? "TDC"
              : data[0].course,
          stream:
            data[0].stream === "" || data[0].stream === null
              ? "ARTS"
              : data[0].stream,
        },
        () => {
          if (
            Array.isArray(this.props.data[0].best_subjects) &&
            this.props.data[0].best_subjects.length == 0
          ) {
            let bs = [];
            if (
              this.state.course === "TDC" &&
              this.state.stream === "SCIENCE"
            ) {
              bs = [
                { subject: "Physics", total_marks: "100", marks_secured: "" },
                { subject: "Chemistry", total_marks: "100", marks_secured: "" },
                {
                  subject: "Mathematics/Biology",
                  total_marks: "100",
                  marks_secured: "",
                },
              ];
              this.setState({
                best_subjects: bs,
              });
            } else if (
              this.state.course === "TDC" &&
              this.state.stream === "ARTS"
            ) {
              bs = [
                { subject: "", total_marks: "100", marks_secured: "" },
                { subject: "", total_marks: "100", marks_secured: "" },
                { subject: "", total_marks: "100", marks_secured: "" },
              ];
              this.setState({
                best_subjects: bs,
              });
            }
          }

          if (
            Array.isArray(this.props.data[0].last_marks) &&
            this.props.data[0].last_marks.length == 0
          ) {
            let lm = [];
            if (this.state.course === "HS") {
              lm = [
                {
                  subject: "MIL/Alt English",
                  total_marks: "100",
                  marks_secured: "",
                },
                {
                  subject: "General Mathematics",
                  total_marks: "100",
                  marks_secured: "",
                },
                { subject: "English", total_marks: "100", marks_secured: "" },
                {
                  subject: "General Science",
                  total_marks: "100",
                  marks_secured: "",
                },
                {
                  subject: "Social Science",
                  total_marks: "100",
                  marks_secured: "",
                },
                {
                  subject: "Elective (e.g Adv. Maths, Hindi, Sanskrit etc)",
                  total_marks: "100",
                  marks_secured: "",
                },
              ];
            } else {
              lm = [
                { subject: "", total_marks: "100", marks_secured: "" },
                { subject: "", total_marks: "100", marks_secured: "" },
                { subject: "", total_marks: "100", marks_secured: "" },
                { subject: "", total_marks: "100", marks_secured: "" },
                { subject: "", total_marks: "100", marks_secured: "" },
              ];
            }
            this.setState({
              last_marks: lm,
              total_marks: 0,
              marks_obtained: 0,
              percentage: 0,
            });
          }
          this.__getData();
        }
      );
    }
  }
  __getData = (type = 0) => {
    if (
      Array.isArray(this.props.data[0].best_subjects) &&
      this.props.data[0].best_subjects.length == 0
    ) {
      let bs = [];
      if (this.state.course === "TDC" && this.state.stream === "SCIENCE") {
        bs = [
          { subject: "Physics", total_marks: "100", marks_secured: "" },
          { subject: "Chemistry", total_marks: "100", marks_secured: "" },
          {
            subject: "Mathematics/Biology",
            total_marks: "100",
            marks_secured: "",
          },
        ];
        this.setState({
          best_subjects: bs,
        });
      } else if (this.state.course === "TDC" && this.state.stream === "ARTS") {
        bs = [
          { subject: "", total_marks: "100", marks_secured: "" },
          { subject: "", total_marks: "100", marks_secured: "" },
          { subject: "", total_marks: "100", marks_secured: "" },
        ];
        this.setState({
          best_subjects: bs,
        });
      }
    }

    if (
      Array.isArray(this.props.data[0].last_marks) &&
      this.props.data[0].last_marks.length == 0
    ) {
      let lm = [];
      if (this.state.course === "HS") {
        lm = [
          { subject: "MIL/Alt English", total_marks: "100", marks_secured: "" },
          {
            subject: "General Mathematics",
            total_marks: "100",
            marks_secured: "",
          },
          { subject: "English", total_marks: "100", marks_secured: "" },
          { subject: "General Science", total_marks: "100", marks_secured: "" },
          { subject: "Social Science", total_marks: "100", marks_secured: "" },
          {
            subject: "Elective (e.g Adv. Maths, Hindi, Sanskrit etc)",
            total_marks: "100",
            marks_secured: "",
          },
        ];
      } else {
        lm = [
          { subject: "", total_marks: "100", marks_secured: "" },
          { subject: "", total_marks: "100", marks_secured: "" },
          { subject: "", total_marks: "100", marks_secured: "" },
          { subject: "", total_marks: "100", marks_secured: "" },
          { subject: "", total_marks: "100", marks_secured: "" },
        ];
      }
      this.setState({
        last_marks: lm,
      });
    }

    let str = this.state.stream;

    if (this.state.course == "PGDCA") {
      str = "NONE";
    }

    GetData(`/${this.state.course}/${str}/getadmissionsubject`).then((resp) => {
      //console.log("EducationalForm -> __getData -> resp", resp)
      //console.log(resp)
      // filter major subjects

      let stream = this.state.stream;
      let course = this.state.course;

      if (this.state.course === "TDC") {
        let subjects = resp; // all subjects

        // 1 - Compulsory Subjects - 1 or 2
        // 2 - MIL Subjets
        // 3 - Major Preference Subject
        // 4 - Generic Preference Subject

        let comp = subjects.filter((el) => el.sub_type === "COMPULSORY"); // Compulsory list
        //console.log("EducationalForm -> __getData -> comp", comp)
        let maj = subjects.filter((el) => el.sub_type === "CORE"); // major list
        let mil = subjects.filter((el) => el.sub_type === "MIL"); // mil list
        let aec = subjects.filter((el) => el.sub_type === "AEC"); // mil list
        let gen = subjects.filter(
          (el) => el.sub_type === "HG" || el.sub_type === "OPTIONAL"
        ); // generic list
        let rc = subjects.filter((el) => el.sub_type === "RC"); // RC list
        let mdc = subjects.filter((el) => el.sub_type === "MDC"); // RC list
        let vac = subjects.filter((el) => el.sub_type === "VAC"); // RC list
        let sec = subjects.filter((el) => el.sub_type === "SE"); // SE list

        // fetch the compulsory subjects

        let comp_1 = {};
        let comp_2 = {};
        let comp_3 = {};

        if (Array.isArray(comp) && comp.length > 0) {
          if (comp.length === 1) {
            comp_1 = comp[0];
          }

          if (comp.length === 2) {
            comp_1 = comp[0];
            comp_2 = comp[1];
          }

          if (comp.length >= 3) {
            comp_1 = comp[0];
            comp_2 = comp[1];
            comp_3 = comp[2];
          }
        }

        this.setState(
          {
            major_list: maj,
            generic_list: gen,
            compulsory_list: comp,
            mil_list: mil,
            mdc_list: mdc,
            f_mdc_list: mdc,
            vac_list: vac,
            aec_list: aec,
            sec_list: sec,
            rc_list: rc,
            f_rc_list: rc,
            compulsory_1: comp_1,
            compulsory_2: comp_2,
            compulsory_3: comp_3,
            f_major_list: maj,
            f_generic_list: gen,
            comp_1: comp_1,
            isLoaded: true,
          },
          () => {
            this.__getSubjects();

            if (this.props.edit) {
              let data = this.props.data;
              if (Array.isArray(data) && data.length > 0) {
                let major_1 = data[0].major_1;
                let major_2 = data[0].major_2;
                let major_3 = data[0].major_3;
                let generic_1 = data[0].generic_1;
                let generic_2 = data[0].generic_2;
                let generic_3 = data[0].generic_3;
                let mil = data[0].mil;
                let compulsory_1 = data[0].compulsory_1;

                if (type === 1) {
                  major_1 = "";
                  major_2 = "";
                  generic_1 = "";
                  generic_2 = "";
                  generic_3 = "";
                  mil = "";
                  compulsory_1 = "";
                }

                if (
                  this.state.course === "TDC" &&
                  this.state.stream === "ARTS" &&
                  this.state.course_type === "REGULAR"
                ) {
                  generic_1 = this.state.comp_1;
                }
                //console.log(generic_1)

                if (this.state.stream == "SCIENCE") compulsory_1 = aec[0];
                if (
                  this.state.stream == "ARTS" &&
                  this.state.course_type == "REGULAR"
                )
                  generic_1 = comp_1;

                this.setState(
                  {
                    major_1: major_1,
                    major_2: major_2,
                    major_3: major_3,
                    //compulsory_1: compulsory_1,
                    generic_1: generic_1,
                    generic_2: generic_2,
                    generic_3: generic_3,
                    mil: mil,
                    optional_subjects: data[0].optional_subjects,
                    subject_comb: data[0].subject_comb,
                  },
                  () => {
                    this.setSubjectFilter();
                  }
                );
              }
            }
          }
        );
      } else if (this.state.course === "HS") {
        let subjects = resp; // all subjects

        let r = subjects.filter((el) => el.course === course);
        let r1 = r; // major
        /* if (r.length > 0) {
                        r.map((el, index) => {
                            let s = el.stream;
                            let s1 = s.filter(el1 => el1.value === stream);
                            if (s1.length > 0) {
                                r1.push(el);
                            }
                        })
                    } */

        //optional subjects
        let r2 = subjects.filter((el) => el.sub_type === "OPTIONAL");
        let comp = subjects.filter((el) => el.sub_type === "COMPULSORY"); // compulsory
        let mil = subjects.filter((el) => el.sub_type === "MIL"); // mil

        // fetch the compulsory subjects

        let comp_1 = {};
        let comp_2 = {};
        let comp_3 = {};
        let mil_hs = {};

        if (Array.isArray(mil) && mil.length > 0) {
          if (mil.length === 1) {
            mil_hs = mil[0];
          }
        }

        if (Array.isArray(comp) && comp.length > 0) {
          if (comp.length === 1) {
            comp_1 = comp[0];
          }

          if (comp.length === 2) {
            comp_1 = comp[0];
            comp_2 = comp[1];
          }

          // if (comp.length >= 3) {
          //     comp_1 = comp[1];
          //     comp_2 = comp[0];
          //     comp_3 = comp[2];
          // }
        }

        this.setState(
          {
            mil_list: mil,
            mil: mil_hs,
            compulsory_list: comp,
            compulsory_1: comp_1,
            compulsory_2: comp_2,
            subject_list: r2,
            f_subject_list: r2,
            isLoaded: true,
          },
          () => {
            this.__getSubjects();
            if (this.props.edit) {
              let data = this.props.data;
              if (Array.isArray(data) && data.length > 0) {
                let op = data[0].optional_subjects;
                if (type === 1) {
                  op = [];
                }
                this.setState({
                  mil: data[0].mil,
                  //compulsory_1: data[0].compulsory_1,
                  optional_subjects: op,
                  subject_comb: data[0].subject_comb,
                });
              }
            }
          }
        );
      } else if (this.state.course === "PGDCA") {
        let subjects = resp; // all subjects

        // 1 - Compulsory Subjects - 1 or 2
        // 2 - MIL Subjets
        // 3 - Major Preference Subject
        // 4 - Generic Preference Subject

        let comp = subjects.filter((el) => el.sub_type === "COMPULSORY"); // Compulsory list
        //console.log("EducationalForm -> __getData -> comp", comp)

        // fetch the compulsory subjects

        let comp_1 = {};
        let comp_2 = {};
        let comp_3 = {};
        let comp_4 = {};
        let comp_5 = {};

        if (Array.isArray(comp) && comp.length > 0) {
          if (comp.length === 1) {
            comp_1 = comp[0];
          }

          if (comp.length === 2) {
            comp_1 = comp[0];
            comp_2 = comp[1];
          }

          if (comp.length >= 3) {
            comp_1 = comp[0];
            comp_2 = comp[1];
            comp_3 = comp[2];
            comp_4 = comp[3];
            comp_5 = comp[4];
          }
        }

        this.setState(
          {
            compulsory_list: comp,

            compulsory_1: comp_1,
            compulsory_2: comp_2,
            compulsory_3: comp_3,
            generic_1: comp_4,
            generic_2: comp_5,

            isLoaded: true,
          },
          () => {
            //console.log(this.state)
            this.__getSubjects();

            if (this.props.edit) {
              let data = this.props.data;
              if (Array.isArray(data) && data.length > 0) {
                let major_1 = data[0].major_1;
                let major_2 = data[0].major_2;
                let generic_1 = data[0].generic_1;
                let generic_2 = data[0].generic_2;
                let generic_3 = data[0].generic_3;
                let mil = data[0].mil;
                let compulsory_1 = data[0].compulsory_1;
                let compulsory_2 = data[0].compulsory_2;
                let compulsory_3 = data[0].compulsory_3;

                if (type === 1) {
                  major_1 = "";
                  major_2 = "";
                  generic_1 = "";
                  generic_2 = "";
                  generic_3 = "";
                  mil = "";
                  compulsory_1 = "";
                  compulsory_2 = "";
                  compulsory_3 = "";
                }

                //console.log(generic_1)
                this.setState(
                  {
                    major_1: major_1,
                    major_2: major_2,
                    // compulsory_1: compulsory_1,
                    // compulsory_2: compulsory_2,
                    // compulsory_3: compulsory_3,
                    // generic_1: generic_1,
                    // generic_2: generic_2,
                    generic_3: generic_3,
                    mil: mil,
                    optional_subjects: data[0].optional_subjects,
                    subject_comb: data[0].subject_comb,
                  },
                  () => {
                    this.setSubjectFilter();
                  }
                );
              }
            }
          }
        );
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      let data = this.props.data;

      if (Array.isArray(data) && data.length > 0) {
        this.setState({
          ...data[0],
        });
      }
    }
  }

  onNewChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChangePhoto = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "subject_comb") {
      let subject_list = this.state.subject_list;
      let r = subject_list.filter((el) => el.id === parseFloat(e.target.value));
      if (Array.isArray(r) && r.length > 0) {
        this.setState({
          optional_subjects: r[0]["subjects"],
        });
      }
    }

    if (
      e.target.name === "course" ||
      e.target.name === "stream" ||
      e.target.name === "course_type"
    ) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          this.__getData(1);
        }
      );
    }
  };

  setSubjectData = (name, data) => {
    this.setState({
      [name]: data,
    });
  };

  setSubjectFilter = () => {
    let r2 = [];
    let major_list = this.state.major_list;
    let mdc_list = this.state.mdc_list;
    let major_1 = this.state.major_1;
    let major_2 = this.state.major_2;
    let major_3 = this.state.major_3;

    let selectedSubject = [];
    // selected major
    if (major_1 && major_1 != null) {
      selectedSubject.push(major_1.value);
    }

    if (major_2 && major_2 != null) {
      selectedSubject.push(major_2.value);
    }

    if (major_3 && major_3 != null) {
      selectedSubject.push(major_3.value);
    }

    // other conditions

    major_list.map((el) => {
      if (!selectedSubject.includes(el.value)) {
        r2.push(el);
      }
    });

    // find MDC subject of these selected subjects
    let mdc_exclude = [];
    selectedSubject.forEach((el) => {
      if (el != null && el != undefined) {
        let r34 = el.split("-");
        if (r34.length > 0) {
          mdc_exclude.push(`${r34[0]}-MDC`);
        }
      }
    });

    let r43 = [];
    mdc_list.map((el) => {
      if (!mdc_exclude.includes(el.value)) {
        r43.push(el);
      }
    });

    this.setState({
      f_major_list: r2,
      f_mdc_list: r43,
    });

    // for generic list

    let r3 = [];
    let generic_list = this.state.generic_list;
    let generic_1 = this.state.generic_1;
    let generic_2 = this.state.generic_2;

    r3 = generic_list.filter((el) => {
      if (generic_1 !== null && generic_2 !== null) {
        if (el.value !== generic_1.value && el.value !== generic_2.value) {
          return el;
        }
      }
      if (generic_1 !== null && generic_2 == null) {
        if (el.value !== generic_1.value) {
          return el;
        }
      }

      if (generic_1 == null && generic_2 !== null) {
        if (el.value !== generic_2.value) {
          return el;
        }
      }
      if (generic_1 == null && generic_2 == null) {
        return el;
      }
    });

    this.setState({
      f_generic_list: r3,
    });

    // for rc list

    let r4 = [];
    let rc_list = this.state.rc_list;

    let generic_3 = this.state.generic_3;

    r4 = rc_list.filter((el) => {
      if (generic_1 !== null && generic_2 !== null && generic_3 != null) {
        if (
          el.value !== generic_1.value &&
          el.value !== generic_2.value &&
          el.value !== generic_3.value
        ) {
          return el;
        }
      }
      if (generic_1 !== null && generic_2 == null) {
        if (el.value !== generic_1.value) {
          return el;
        }
      }

      if (generic_1 == null && generic_2 !== null) {
        if (el.value !== generic_2.value) {
          return el;
        }
      }
      if (generic_1 == null && generic_2 == null) {
        return el;
      }
    });

    this.setState({
      f_rc_list: r4,
    });
  };

  //  checkSubjectLimit =(sub_code,limit)=> {
  //     GetData(`/checksubjectlimit/${sub_code}`)
  //     .then((resp)=>{
  //         if(parseInt(resp)<parseInt(limit))
  //         return true;
  //         else return false;
  //     })
  // }

  onSubChange = (name, e) => {
    //console.log(e)
    // let sub_code = e !=null && e !=undefined?e.sub_code:'';
    // if(sub_code=='EDU-HG' || sub_code=='POL-HG' || sub_code=='EDU-RC' || sub_code=='POL-RC' || sub_code=='PHY-HG' || sub_code=='CHE-HG' || sub_code=='MAT-HG' || sub_code=='BOT-HG' || sub_code=='ZOO-HG' || sub_code=='PHY-RC' || sub_code=='CHE-RC' || sub_code=='MAT-RC' || sub_code=='BOT-RC' || sub_code=='ZOO-RC'){
    //     GetData(`/checksubjectlimit/${sub_code}`)
    //     .then((resp)=>{
    //         let limit =0;
    //         if (sub_code=='EDU-HG' || sub_code=='POL-HG')
    //          limit = 50;
    //         else if(sub_code=='EDU-RC' || sub_code=='POL-RC')
    //         limit = 200;
    //         else if(sub_code=='PHY-HG' || sub_code=='CHE-HG' || sub_code=='MAT-HG' || sub_code=='BOT-HG' || sub_code=='ZOO-HG' || sub_code=='PHY-RC' || sub_code=='CHE-RC' || sub_code=='MAT-RC' || sub_code=='BOT-RC' || sub_code=='ZOO-RC')
    //         limit=30;
    //         if(parseInt(resp)<limit)
    //         {
    //             this.setState({
    //                 [name]: e
    //             }, () => {
    //                 let nn = 0;
    //                 if (this.state.course === 'TDC') {
    //                     this.setSubjectFilter();
    //                 } else if (this.state.course === 'HS') {
    //                     nn = 4;
    //                     if (name === 'optional_subjects') {
    //                         let v = this.state.optional_subjects;
    //                         if (v.length >= nn) {
    //                             this.setState({
    //                                 f_subject_list: []
    //                             })
    //                         } else {
    //                             this.setState({
    //                                 f_subject_list: this.state.subject_list
    //                             })
    //                         }
    //                     }
    //                 }

    //             })
    //         }
    //         else alert('No seat available.Select different subject.');
    //     })
    // }
    // else {
    this.setState(
      {
        [name]: e,
      },
      () => {
        let nn = 0;
        if (this.state.course === "TDC") {
          // if (name == "major_1") {
          //   if (e && e.value == "PHY-FYUGP") {
          //     let math = this.state.major_list.find(
          //       (el) => el.value == "MAT-FYUGP"
          //     );
          //     if (math) {
          //       this.setState({
          //         major_2: math,
          //         major_2_disabled: true,
          //       });
          //     }
          //   } else {
          //     this.setState({
          //       major_2_disabled: false,
          //     });
          //   }

          //   if (e && ["BOT-FYUGP", "ZOO-FYUGP"].includes(e.value)) {
          //     let math = this.state.major_list.find(
          //       (el) => el.value == "CHE-FYUGP"
          //     );
          //     if (math) {
          //       this.setState({
          //         major_2: math,
          //         major_2_disabled: true,
          //       });
          //     }
          //   } else {
          //     this.setState({
          //       major_2_disabled: false,
          //     });
          //   }
          // }

          // if (name == "major_2") {
          //   if (e && e.value == "PHY-FYUGP") {
          //     let math = this.state.major_list.find(
          //       (el) => el.value == "MAT-FYUGP"
          //     );
          //     if (math) {
          //       this.setState({
          //         major_3: math,
          //         major_3_disabled: true,
          //       });
          //     }
          //   } else {
          //     this.setState({
          //       major_3_disabled: false,
          //     });
          //   }

          //   if (e && ["BOT-FYUGP", "ZOO-FYUGP"].includes(e.value)) {
          //     let math = this.state.major_list.find(
          //       (el) => el.value == "CHE-FYUGP"
          //     );
          //     if (math) {
          //       this.setState({
          //         major_3: math,
          //         major_3_disabled: true,
          //       });
          //     }
          //   } else {
          //     this.setState({
          //       major_3_disabled: false,
          //     });
          //   }
          // }

          this.setSubjectFilter();
        } else if (this.state.course === "HS") {
          nn = 4;
          if (name === "optional_subjects") {
            let v = this.state.optional_subjects;
            if (v.length >= nn) {
              this.setState({
                f_subject_list: [],
              });
            } else {
              this.setState({
                f_subject_list: this.state.subject_list,
              });
            }
          }
        }
      }
    );
    //}
  };

  handleValidation = () => {
    let {
      major_1,
      major_2,
      major_3,
      compulsory_1,
      compulsory_2,
      compulsory_3,
      generic_1,
      generic_2,
      generic_3,
      mil,
      course,
      stream,
      course_type,
      optional_subjects,
      exam_passed,
      best_subjects,
      last_marks,
      errors,
      percentage,
      caste,
    } = this.state;
    let isValid = true;

    if (course == "PGDCA") {
      if (Array.isArray(last_marks) && last_marks.length === 0) {
        isValid = false;
        errors["last_marks"] = "Please fill up this field";
      } else {
        errors["last_marks"] = "";
      }
    }

    if (course === "HS") {
      let p = parseFloat(percentage);

      if (Array.isArray(last_marks) && last_marks.length === 0) {
        isValid = false;
        errors["last_marks"] = "Please fill up this field";
      } else {
        errors["last_marks"] = "";
      }

      if (p < 30 && caste == "GENERAL") {
        alert(
          "Your percentage is below 40. You can not apply. Please view prospectus for details."
        );
        isValid = false;
      } else {
        isValid = true;
      }

      if (compulsory_1 !== null) {
        if (Object.entries(compulsory_1).length === 0) {
          isValid = false;

          errors["compulsory_1"] = "Please fill up this field";
        } else {
          errors["compulsory_1"] = "";
        }
      } else {
        isValid = false;
        errors["compulsory_1"] = "Please fill up this field";
      }

      if (compulsory_2 !== null) {
        if (Object.entries(compulsory_2).length === 0) {
          isValid = false;

          errors["compulsory_2"] = "Please fill up this field";
        } else {
          errors["compulsory_2"] = "";
        }
      } else {
        isValid = false;
        errors["compulsory_2"] = "Please fill up this field";
      }

      if (mil !== null && mil !== undefined) {
        if (Object.entries(mil).length === 0) {
          isValid = false;

          errors["mil"] = "Please fill up this field";
        } else {
          errors["mil"] = "";
        }
      } else {
        isValid = false;

        errors["mil"] = "Please fill up this field";
      }

      if (Array(optional_subjects) && optional_subjects.length <= 3) {
        isValid = false;
        errors["optional_subjects"] = "You have to fill all 4 subjects";
      } else {
        errors["optional_subjects"] = "";
      }
    }

    if (course === "TDC") {
      if (mil !== null) {
        if (Object.entries(mil).length === 0) {
          isValid = false;

          errors["mil"] = "Please fill up this field";
        } else {
          errors["mil"] = "";
        }
      } else {
        isValid = false;
        errors["mil"] = "Please fill up this field";
      }

      if(this.state.stream == "ARTS" || this.state.stream == "SCIENCE"){

      if (major_1 !== null) {
        if (Object.entries(major_1).length === 0) {
          isValid = false;

          errors["major_1"] = "Please fill up this field";
        } else {
          errors["major_1"] = "";
        }
      } else {
        isValid = false;
        errors["major_1"] = "Please fill up this field";
      }

      if (major_2 !== null) {
        if (Object.keys(major_2).length === 0) {
          isValid = false;
          errors["major_2"] = "Please fill up this field";
        } else {
          errors["major_2"] = "";
        }
      } else {
        isValid = false;
        errors["major_2"] = "Please fill up this field";
      }

      

    }


    if(this.state.stream == "COMMERCE"){

        if (compulsory_1 !== null) {
          if (Object.entries(compulsory_1).length === 0) {
            isValid = false;
  
            errors["compulsory_1"] = "Please fill up this field";
          } else {
            errors["compulsory_1"] = "";
          }
        } else {
          isValid = false;
          errors["compulsory_1"] = "Please fill up this field";
        }
  
        if (compulsory_2 !== null) {
          if (Object.keys(compulsory_2).length === 0) {
            isValid = false;
            errors["compulsory_2"] = "Please fill up this field";
          } else {
            errors["compulsory_2"] = "";
          }
        } else {
          isValid = false;
          errors["compulsory_2"] = "Please fill up this field";
        }
  
        if (compulsory_3 !== null) {
          if (Object.keys(compulsory_3).length === 0) {
            isValid = false;
            errors["compulsory_3"] = "Please fill up this field";
          } else {
            errors["compulsory_3"] = "";
          }
        } else {
          isValid = false;
          errors["compulsory_3"] = "Please fill up this field";
        }
  
      }

      if (generic_1 !== null) {
        if (Object.keys(generic_1).length === 0) {
          isValid = false;
          errors["generic_1"] = "Please fill up this field";
        } else {
          errors["generic_1"] = "";
        }
      } else {
        isValid = false;
        errors["generic_1"] = "Please fill up this field";
      }

      if (generic_2 !== null) {
        if (Object.keys(generic_2).length === 0) {
          isValid = false;
          errors["generic_2"] = "Please fill up this field";
        } else {
          errors["generic_2"] = "";
        }
      } else {
        isValid = false;
        errors["generic_2"] = "Please fill up this field";
      }


      if (generic_3 !== null) {
        if (Object.keys(generic_3).length === 0) {
          isValid = false;
          errors["generic_3"] = "Please fill up this field";
        } else {
          errors["generic_3"] = "";
        }
      } else {
        isValid = false;
        errors["generic_3"] = "Please fill up this field";
      }

    
    }

    // for HS newly added
    if (this.state.njc_student === null || this.state.njc_student === "") {
      isValid = false;
      errors["njc_student"] = "Please fill up this field";
    } else {
      errors["njc_student"] = "";
    }

    if (Array.isArray(exam_passed) && exam_passed.length === 0) {
      isValid = false;
      errors["exam_passed"] = "Please fill up this field";
    } else {
      errors["exam_passed"] = "";
    }

    if (Array.isArray(last_marks) && last_marks.length === 0) {
      isValid = false;
      errors["last_marks"] = "Please fill up this field";
    } else {
      errors["last_marks"] = "";
    }

    if (course === "TDC") {
      if (Array.isArray(best_subjects) && best_subjects.length === 0) {
        isValid = false;
        errors["best_subjects"] = "Please fill up this field";
      } else {
        errors["best_subjects"] = "";
      }
    }

    this.setState({
      errors,
    });
    console.log(errors);
    return isValid;
  };

  onSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state)

    if (this.handleValidation()) {
      let d = {
        email: this.state.email,
        njc_student: this.state.njc_student,
        course: this.state.course,
        stream: this.state.stream,
        total_marks: this.state.total_marks,
        marks_obtained: this.state.marks_obtained,
        percentage: this.state.percentage,
        course_type: this.state.course_type,
        exam_passed: this.state.exam_passed,
        best_subjects: this.state.best_subjects,
        last_marks: this.state.last_marks,
        subject_comb: this.state.subject_comb,

        major_1: this.state.major_1,
        major_2: this.state.major_2,
        major_3: this.state.major_3,
        compulsory_1: this.state.compulsory_1,
        compulsory_2: this.state.compulsory_2,
        compulsory_3: this.state.compulsory_3,
        mil: this.state.mil,
        generic_1: this.state.generic_1,
        generic_2: this.state.generic_2,
        generic_3: this.state.generic_3,
        optional_subjects: this.state.optional_subjects,

        is_free_admission: this.state.is_free_admission,
        has_gap: this.state.has_gap,
        gap_admission: this.state.gap_admission,
        gap_course: this.state.gap_course,
        gap_institute: this.state.gap_institute,
        gap_reason: this.state.gap_reason,
        gap_roll_no: this.state.gap_roll_no,
      };
      //console.log(d)
      //let d = this.state;
      //newly added
      this.props.setData(d);
      // confirmAlert({
      //     title: 'Confirm to submit',
      //     message: 'Are you sure to do this.',
      //     buttons: [
      //         {
      //             label: 'Yes',
      //             onClick: () => this.props.setData(d)
      //         },
      //         {
      //             label: 'No'
      //         }
      //     ]
      // });
    }
  };

  showData(value) {
    if (Array.isArray(value) && value.length > 0) {
      let new_arr = value.map((el, index) => el.label);

      return new_arr.join(",");
    } else {
      return "";
    }
  }

  sentTotalData = (data) => {
    this.setState({
      total_marks: data.over_all,
      marks_obtained: data.obtained,
      percentage: data.percentage,
    });
  };

  showMePapers = (subject) => {
    if (subject !== null) {
      if (Object.keys(subject).length > 0) {
        return (
          <div>
            <AlertDiv back={orange[100]} border={orange[400]}>
              <div style={{ fontSize: 13 }}>Available Papers</div>

              <ul>
                {Array.isArray(subject.papers) &&
                  subject.papers
                    .filter((el) => {
                      return el.semester == 1;
                    })
                    .map((el, index) => (
                      <li style={{ fontSize: 12 }} key={index}>
                        {el.paper_title}
                      </li>
                    ))}
              </ul>
            </AlertDiv>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  render() {
    let { classes } = this.props;
    let i = [];
    let subject_list = this.state.subject_list;
    if (Array.isArray(subject_list)) {
      i = subject_list.map((el, index) => {
        return (
          <MenuItem value={el.id} key={index}>
            {this.showData(el.subjects)}
          </MenuItem>
        );
      });
    }

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h5">Educational Details</Typography>
          <Typography>
            All <span className="red-alert">*</span> marked fields are mandatory
          </Typography>

          <br />
          <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Course Applied For <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="course"
                    required
                    value={this.state.course}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="HS">HS - Higher Secondary</option>
                    <option value="TDC">
                      FYUGP - Four Year Under Graduate Program{" "}
                    </option>
                    {/* 
                                        <option value="PGDCA">PGDCA</option> */}
                  </select>
                </Grid>

                {this.state.course === "TDC" || this.state.course === "HS" ? (
                  <Grid item xs={12} sm={12} lg={3} md={3}>
                    <Typography variant="subtitle2">
                      Stream{" "}
                      {this.state.course == "TDC" &&
                        `(may change depending on the subjects taken)`}{" "}
                      <span className="red-alert">*</span>
                    </Typography>
                    <select
                      className="form-control"
                      type="text"
                      name="stream"
                      required
                      value={this.state.stream}
                      onChange={this.onChange}
                    >
                      <option value="">Select</option>
                      <option value="ARTS">ARTS</option>
                      <option value="SCIENCE">SCIENCE</option>
                      {this.state.course === "TDC" && (
                        <option value="COMMERCE">COMMERCE</option>
                      )}
                    </select>
                  </Grid>
                ) : null}

                {/* {this.state.course === 'TDC' ? (<Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Course Type <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="course_type"
                                        required
                                        value={this.state.course_type}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Couse type</option>
                                        <option value="HONOURS">HONOURS (HC)</option>
                                        <option value="REGULAR">REGULAR (RC)</option>

                                    </select>
                                </Grid>) : null} */}
              </Grid>

              {this.state.course === "TDC" ? (
                <div>
                  <AlertDiv back={orange[100]} border={orange[400]}>
                    <Typography variant="subtitle2">
                      <Grade style={{ fontSize: 10 }} /> Important points to be
                      noted before you choose the subjects:
                    </Typography>

                    <ul>
                      <li>
                        <Typography>
                          Fill up the subjects accordingly
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          Please go through the prospectus for more information
                          about the course structure.
                        </Typography>
                      </li>
                      {/* <li><Typography style={{ color: 'red' }} variant="body2">You cannot select the same subject as Honours and Generic at the same time</Typography></li>
                                            <li><Typography>You can see avalilable papers after you select one subject</Typography></li>
                                            <li><Typography style={{ color: 'red' }} variant="body2">Honours will be offered as per general merit list prepared by the college and on " First come First Serve" basis.</Typography></li>
                                            <li><Typography style={{ color: 'red' }} variant="body2">
                                                Selection to the Honours courses will be approved by the concerned department prior to the day of admission subject
                                                to the availability of seat but however, the student must secure not less than 50% in the subject opted for honours and
                                                50% aggregate in HS final (10+2) Exam.</Typography></li>
                                            <li><Typography style={{ color: 'red' }} variant="body2">Merit List for Honours in Zoology & Botany will be prepared on the basis of marks obtained in Physics, Chemistry &
                                                Biology and for Honours in Physics, Chemistry & Mathematics will be prepared on the basis of marks of Physics,
                                                Chemistry & Mathematics</Typography></li>
                                            <li><Typography style={{ color: 'red' }} variant="body2"> For Honours in Physics, Chemistry & Economics the candidate must secure pass mark in mathematics in HS final
                                                (10+2) Exam.</Typography></li> */}
                    </ul>
                  </AlertDiv>
                </div>
              ) : null}

              {this.state.course === "TDC" ? (
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Combination of subjects Sought for 1st Semester (
                      {this.state.stream} {this.props.course_type}){" "}
                      <span className="red-alert">*</span>
                    </Typography>
                  </Grid>

                  {(this.state.stream == "ARTS" ||
                    this.state.stream == "SCIENCE") && (
                    <Grid item xs={12} sm={12} lg={4} md={4}>
                      <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                        <CardHeader title={`Core Course`} subheader={`CC`} />
                        <Divider />
                        <div style={{ padding: "10px" }}>
                          <Grid container spacing={24}>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["major_1"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["major_1"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Desired Major (Core 1){" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.f_major_list}
                                value={this.state.major_1}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={this.state.major_1_disabled}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "major_1"
                                )}
                              />
                              {/* {this.showMePapers(this.state.major_1)} */}
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["major_2"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["major_2"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Minor 1 <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.f_major_list}
                                value={this.state.major_2}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={this.state.major_2_disabled}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "major_2"
                                )}
                              />
                              {/* {this.showMePapers(this.state.major_2)} */}
                            </Grid>

                            {/* <Grid item xs={12} sm={12} lg={12} md={12}>

                                                        {this.state.errors['major_3'] !== '' ? (

                                                            <div>
                                                                <Typography
                                                                    variant="caption"
                                                                    style={{ color: 'red' }}
                                                                >
                                                                    {this.state.errors['major_3']}
                                                                </Typography>
                                                            </div>

                                                        ) : null}


                                                        <Typography
                                                            variant="subtitle2"

                                                        >
                                                            Minor 2 <span className="red-alert">*</span>
                                                        </Typography>
                                                        <Sel
                                                            options={this.state.f_major_list}
                                                            value={this.state.major_3}
                                                            isLoading={!this.state.isLoaded}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            isDisabled={this.state.major_3_disabled}
                                                            onChange={this.onSubChange.bind(this, 'major_3')}
                                                        />
                                                 

                                                    </Grid> */}
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                  )}

                  {this.state.stream == "COMMERCE" && (
                    <Grid item xs={12} sm={12} lg={4} md={4}>
                      <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                        <CardHeader
                          title={`Compulsory Course`}
                          subheader={`CC`}
                        />
                        <Divider />
                        <div style={{ padding: "10px" }}>
                          <Grid container spacing={24}>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["compulsory_1"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["compulsory_1"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Core Subject 1{" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.compulsory_list}
                                value={this.state.compulsory_1}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={this.state.major_1_disabled}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "compulsory_1"
                                )}
                              />
                              {/* {this.showMePapers(this.state.major_1)} */}
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["compulsory_2"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["compulsory_2"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Core Subject 2
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.compulsory_list}
                                value={this.state.compulsory_2}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={this.state.major_1_disabled}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "compulsory_2"
                                )}
                              />
                              {/* {this.showMePapers(this.state.major_1)} */}
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["compulsory_3"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["compulsory_3"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Core Subject 3
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.compulsory_list}
                                value={this.state.compulsory_3}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={this.state.major_1_disabled}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "compulsory_3"
                                )}
                              />
                              {/* {this.showMePapers(this.state.major_1)} */}
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                  )}

                  {/* {this.state.course_type === 'REGULAR' && <Grid item xs={12} sm={12} lg={4} md={4}>

                                        <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                                            <CardHeader
                                                title={`Core Course`}
                                                subheader={`CC`}
                                            />
                                            <Divider />
                                            <div style={{ padding: '10px' }}>
                                                <Grid container spacing={24}>
                                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                                        {this.state.errors['generic_1'] !== '' ? (

                                                            <div>
                                                                <Typography
                                                                    variant="caption"
                                                                    style={{ color: 'red' }}
                                                                >
                                                                    {this.state.errors['generic_1']}
                                                                </Typography>
                                                            </div>

                                                        ) : null}

                                                        <Typography
                                                            variant="subtitle2"

                                                        >
                                                            {this.state.stream=='SCIENCE'?'Regular Core 1':'English Core'} 
                                                        </Typography>
                                                        {this.state.stream=='ARTS'?  <Typography
                                                            variant="subtitle2"

                                                        >
                                                            {this.state.generic_1.sub_name}
                                                            </Typography>:
                                                         <Sel
                                                            options={this.state.f_rc_list}
                                                            value={this.state.generic_1}
                                                            isLoading={!this.state.isLoaded}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={this.onSubChange.bind(this, 'generic_1')}
                                                        />}

                                                        {this.showMePapers(this.state.generic_1)}

                                                    </Grid>

                                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                                        {this.state.errors['generic_2'] !== '' ? (

                                                            <div>
                                                                <Typography
                                                                    variant="caption"
                                                                    style={{ color: 'red' }}
                                                                >
                                                                    {this.state.errors['generic_2']}
                                                                </Typography>
                                                            </div>

                                                        ) : null}

                                                        <Typography
                                                            variant="subtitle2"

                                                        >
                                                            {this.state.stream=='SCIENCE'?'Regular Core 2':'Regular Core 1'} <span className="red-alert">*</span>
                                                        </Typography>
                                                        <Sel
                                                            options={this.state.f_rc_list}
                                                            value={this.state.generic_2}
                                                            isLoading={!this.state.isLoaded}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={this.onSubChange.bind(this, 'generic_2')}
                                                        />

                                                        {this.showMePapers(this.state.generic_2)}

                                                    </Grid>

                                                    <Grid item xs={12} sm={12} lg={12} md={12}>
                                                        {this.state.errors['generic_3'] !== '' ? (

                                                            <div>
                                                                <Typography
                                                                    variant="caption"
                                                                    style={{ color: 'red' }}
                                                                >
                                                                    {this.state.errors['generic_3']}
                                                                </Typography>
                                                            </div>

                                                        ) : null}

                                                        <Typography
                                                            variant="subtitle2"

                                                        >
                                                            {this.state.stream=='SCIENCE'?'Regular Core 3':'Regular Core 2'} <span className="red-alert">*</span>
                                                        </Typography>
                                                        <Sel
                                                            options={this.state.f_rc_list}
                                                            value={this.state.generic_3}
                                                            isLoading={!this.state.isLoaded}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={this.onSubChange.bind(this, 'generic_3')}
                                                        />

                                                        {this.showMePapers(this.state.generic_3)}

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Paper>





                                    </Grid>} */}
                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${green[700]}` }}>
                      <CardHeader title={`Common Course`} />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Multi-Disciplinary Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_mdc_list}
                              value={this.state.generic_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_1"
                              )}
                            />

                            {/* {this.showMePapers(this.state.generic_1)} */}
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Value Added Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.vac_list}
                              value={this.state.generic_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_2"
                              )}
                            />

                            {/* {this.showMePapers(this.state.generic_1)} */}

                            <br />

                            {this.state.errors["mil"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["mil"]}
                                </Typography>
                              </div>
                            ) : null}
                            <React.Fragment>
                              <Typography variant="subtitle2">
                                Ability Enhancement Course{" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.aec_list}
                                value={this.state.mil}
                                isLoading={!this.state.isLoaded}
                                isClearable={false}
                                isSearchable={true}
                                onChange={this.onSubChange.bind(this, "mil")}
                              />
                              {/* {this.showMePapers(this.state.compulsory_1)} */}
                            </React.Fragment>

                            <br />

                            {this.state.errors["generic_3"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_3"]}
                                </Typography>
                              </div>
                            ) : null}
                            <React.Fragment>
                              <Typography variant="subtitle2">
                                Skill Enhancement Course{" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.sec_list}
                                value={this.state.generic_3}
                                isLoading={!this.state.isLoaded}
                                isClearable={false}
                                isSearchable={true}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "generic_3"
                                )}
                              />
                              {/* {this.showMePapers(this.state.compulsory_1)} */}
                            </React.Fragment>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}

           

            

              {this.state.course === "HS" ? (
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Combination of subjects Sought for HS Course{" "}
                      <span className="red-alert">*</span>
                    </Typography>

                    <div>
                      <AlertDiv back={green[100]} border={green[400]}>
                        <Typography variant="subtitle2">
                          <Grade style={{ fontSize: 10 }} /> English
                          ,Environmental Studies and MIL(Assamese) are
                          compulsory subjects for all HS Courses.
                          <br />
                          <Grade style={{ fontSize: 10 }} /> Admission Form will
                          be accepted only to applicants securing aggregate
                          marks 40% or above in H.S. final (10+2) in both
                          (Science & Arts) Streams. <br />
                          <Grade style={{ fontSize: 10 }} />
                          There will be 5% relaxation in aggregate marks to the
                          applicants passing out from Bhawanipur Anchalik
                          College and also candidates of reserved category.
                        </Typography>

                        {this.state.stream === "ARTS" && (
                          <Typography variant="subtitle2">
                            <Grade style={{ fontSize: 10 }} /> You cannot take
                            Adv. Assamsese and Arabic at the same time
                          </Typography>
                        )}
                      </AlertDiv>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    {this.state.errors["compulsory_1"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["compulsory_1"]}
                        </Typography>
                      </div>
                    ) : null}

                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Compulsory Subject - 1
                    </Typography>

                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      {this.state.compulsory_1.sub_name}
                    </Typography>

                    {/* <Sel
                                            options={this.state.compulsory_list}
                                            value={this.state.compulsory_1}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'compulsory_1')}
                                        /> */}
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    {this.state.errors["compulsory_2"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["compulsory_2"]}
                        </Typography>
                      </div>
                    ) : null}

                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Compulsory Subject - 2
                    </Typography>

                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      {this.state.compulsory_2.sub_name}
                    </Typography>

                    {/* <Sel
                                            options={this.state.compulsory_list}
                                            value={this.state.compulsory_2}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'compulsory_2')}
                                        /> */}
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    {this.state.errors["mil"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["mil"]}
                        </Typography>
                      </div>
                    ) : null}

                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      MIL Subject
                    </Typography>

                    <Sel
                      options={this.state.mil_list}
                      value={this.state.mil}
                      isLoading={!this.state.isLoaded}
                      isClearable={true}
                      isSearchable={true}
                      onChange={this.onSubChange.bind(this, "mil")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    {/*this.state.errors['subject_comb'] !== '' ? (
 
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['subject_comb']}
                                                </Typography>
                                            </div>
 
                                        ) : null*/}

                    {/* <FormControl variant="outlined" required className={classes.formControl}>
                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Select One Combination <span className="red-alert">*</span>
                                        </Typography>
                                            
                                            <Select
                                                value={this.state.subject_comb}
                                                onChange={this.onChange}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={200}
                                                        name="subject_comb"

                                                    />
                                                }
                                                required
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {i}
                                            </Select>
                                        </FormControl> */}

                    {this.state.errors["optional_subjects"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["optional_subjects"]}
                        </Typography>
                      </div>
                    ) : null}

                    {this.state.isLoaded ? (
                      <MultiSubject
                        subjects={this.state.f_subject_list}
                        nos={4}
                        setData={this.setSubjectData.bind(
                          this,
                          "optional_subjects"
                        )}
                        data={this.state.optional_subjects}
                        edit={this.props.edit}
                      />
                    ) : (
                      `Loading Combinations`
                    )}
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Previous Examination Details{" "}
                    <span className="red-alert">*</span>
                  </Typography>

                  {this.state.errors["exam_passed"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["exam_passed"]}
                      </Typography>
                    </div>
                  ) : null}

                  <PreviousExamination
                    onNewChange={this.onNewChange.bind(this, "exam_passed")}
                    sentTotalData={this.sentTotalData.bind(this)}
                    data={this.state.exam_passed}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Are you a student of This College ?{" "}
                    <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="njc_student"
                    required
                    value={this.state.njc_student}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Do you have any gap ? <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="has_gap"
                    required
                    value={this.state.has_gap}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </Grid>

                {this.state.has_gap === "YES" && (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                      >
                        Have you taken admission in any institute/college ?{" "}
                        <span className="red-alert">*</span>
                      </Typography>
                      <select
                        className="form-control"
                        type="text"
                        name="gap_admission"
                        required
                        value={this.state.gap_admission}
                        onChange={this.onChange}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </Grid>

                    {this.state.gap_admission === "YES" && (
                      <React.Fragment>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Name of the course{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_course"
                            value={this.state.gap_course}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Name of the Institute{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_institute"
                            value={this.state.gap_institute}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Enrollment No/Registration No{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_roll_no"
                            value={this.state.gap_roll_no}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                      >
                        Reason of Gap <span className="red-alert">*</span>
                      </Typography>
                      <textarea
                        className="form-control"
                        type="text"
                        name="gap_reason"
                        value={this.state.gap_reason}
                        onChange={this.onChange}
                        required
                      ></textarea>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <AlertDiv back={orange[100]} border={orange[400]}>
                        <strong>Warning !!</strong>

                        <Typography>
                          In case, if any false information detected about the
                          gap period, the application/admission will be
                          rejected.
                        </Typography>
                      </AlertDiv>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>

              <Grid container spacing={16}>
                {this.state.course === "TDC" ? (
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    {this.state.errors["best_subjects"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["best_subjects"]}
                        </Typography>
                      </div>
                    ) : null}
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Best of Three Subjects{" "}
                      <span className="red-alert">*</span>
                    </Typography>

                    {this.state.stream === "SCIENCE" ? (
                      <AlertDiv back={lightGreen[100]} border={lightGreen[400]}>
                        <Typography>
                          For Science Stream Please add Physics, Chemistry ,
                          Mathematics / Biology marks as best of three.
                          Otherwise your application may get rejected.
                        </Typography>
                      </AlertDiv>
                    ) : null}

                    <BestOfThree
                      onNewChange={this.onNewChange.bind(this, "best_subjects")}
                      type={0}
                      rows={3}
                      data={this.state.best_subjects}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  {this.state.errors["last_marks"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["last_marks"]}
                      </Typography>
                    </div>
                  ) : null}
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Marks Obtained in last examination passed (Best Subjects
                    only.) <span className="red-alert">*</span>
                  </Typography>

                  {this.state.course === "HS" ? (
                    <BestOfThree
                      onNewChange={this.onNewChange.bind(this, "last_marks")}
                      type={1}
                      sentTotalData={this.sentTotalData.bind(this)}
                      rows={8}
                      data={this.state.last_marks}
                    />
                  ) : (
                    <BestOfThree
                      onNewChange={this.onNewChange.bind(this, "last_marks")}
                      type={1}
                      sentTotalData={this.sentTotalData.bind(this)}
                      rows={5}
                      data={this.state.last_marks}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>

            <CardActions className={classes.cardActions}>
              <Button type="button" onClick={this.props.back}>
                Previous
              </Button>
              <Button
                variant="contained"
                className={classes.buttonSubmit}
                type="submit"
              >
                Save &amp; Proceed to next step
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(EducationalForm);
