import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import withAuth from '../auth_components/js/withAuth';
import LoggedNavBar from '../Components/HostelAdmission/LoggedNavBar';
import FormsLanding from '../Components/HostelAdmission/Registration/Forms/FormsLanding';
import ViewForm from '../Components/HostelAdmission/Registration/View/ViewForm';



class HomePageUserHostel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){

    //   if(this.props.user.user.role == 'RESTUDENT'){
    //     this.props.history.replace(`/rehome`)
    //   }

  }

  render() {
    //console.log(this.props);
    return (
      <div>
        <LoggedNavBar
          history={this.props.history}
          type={1}
        />
        <div className="main-body">

          <Switch>
            <Route
              exact
              path="/hostel-home"
              render={(props) => <FormsLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/hostel-final"
              render={(props) => <ViewForm
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            {/* <Route
              exact
              path="/admission"
              render={(props) => <AdmissionLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />


            <Route
              exact
              path="/admission/ack"
              render={(props) => <AckLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />
            <Route
              exact
              path="/paymentresponse"
              render={(props) => <PaymentResponse
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            /> */}
          </Switch>



        </div>
      </div>
    )
  }
}

export default withAuth(HomePageUserHostel);