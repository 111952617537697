import React, { Component } from 'react';
import Navbar from '../Components/Navbar';

import ReRegistrationLanding from '../Components/ReRegistration/ReRegistrationLanding';
import { Typography } from '@material-ui/core';
import AlertDiv from '../Components/Registration/Forms/AlertDiv';
import { orange } from '@material-ui/core/colors';


class ReRegistrationContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
        />
        <div className="main-body">

          <div>
            <Typography
              variant="h5"
              gutterBottom
            >
              Re-Admission Portal (For Existing student of the college)
                </Typography>

            <AlertDiv
              back={orange[100]}
              border={orange[400]}
            >
              <Typography
                variant="body2"
              >
                Please read this instructions very carefully before continue
                    </Typography>

              <ul>
                <li>
                  <Typography>
                    You must be a student of this college to fill up this form
                            </Typography>
                </li>
                <li>
                  <Typography>
                    Ensure that the information that you have given here are correct. Some informations cannot be changed afterwards
                            </Typography>
                </li>
                <li>
                  <Typography>
                    You must submit Unique ID generated from DHE website along with the Unique ID Acknowledgement Receipt
                            </Typography>
                </li>
                <li>
                  <Typography>
                    Choose the subjects that you have taken in the appropriate fields mentioned in the forms.
                            </Typography>
                </li>
                <li>
                  <Typography>
                    For any queries or issues, please contact our office for support
                            </Typography>
                </li>
              </ul>

            </AlertDiv>

            <br />


          </div>

          <ReRegistrationLanding
            history={this.props.history}
          />
        </div>
      </div>
    )
  }
}

export default ReRegistrationContainer;