import React, { Component } from 'react'
import { withStyles, Button, Typography, Chip, CircularProgress } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { PostFormData, DeleteData, GetData } from '../../../../api/service';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@material-ui/icons/HelpOutline'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'


const styles = theme => ({
    container: {
        backgroundColor: grey[100],

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    photoHolder: {
        //border: ' solid thin lightgrey',
        width: 100,
        //backgroundColor: 'white'
    },
    uploaderDiv: {
        padding: '0px',
        display: 'flex',
        backgroundColor: 'white',
        width: '100%',
        border: `solid thin ${grey[100]}`,
        alignItems: 'center',
        justifyContent: 'center'
    },
    uploadedFile: {
        width: '100%',
        height: '100%'
    }
})

const file_upload_limit = 500;



const allowed_ext = ['image/jpg', 'image/png', 'image/jpeg'];

class FileUpload extends Component {
    state = {
        type: this.props.type,
        isUploading: false,
        fileView: '',
        file: [],
        isUploaded: false,
        result: [],
        isValid: false,
        error: '',
        limit: this.props.limit || 500,
        dimentions: {
            width: this.props.width || 0,
            height: this.props.height || 0
        }

    }

    uploadFile = () => {


        if (this.state.fileView !== '') {
            this.setState({
                isUploading: true
            })

            let formdata = new FormData();
            formdata.append('type', this.state.type);
            let index = 0;
            for (const f of this.state.file) {
                formdata.append(`file[${index}]`, f);
                index++;
            }

            PostFormData(`/${this.props.apikey}/addtempfile`, formdata)
                .then((resp) => {

                    if (resp !== false) {
                        this.setState({
                            result: resp,
                            isUploaded: true
                        }, () => {
                            this.props.onChange(this.state.result)
                        })
                    }
                })
        }
    }

    deleteFile = () => {
        let result = this.state.result;
        if (result.length > 0) {
            // perform the delete
            let file_id = result[0].file_id;

            DeleteData(`/${this.props.apikey}/${file_id}/deletetempfile`)
                .then((resp) => {
                    if (resp) {
                        this.setState({
                            result: [],
                            isUploading: false,
                            isUploaded: false,
                            file: [],
                            fileView: ''
                        }, () => {
                            this.props.onChange(this.state.result)
                        })
                    } else {
                        this.setState({
                            result: [],
                            isUploading: false,
                            isUploaded: false,
                            file: [],
                            fileView: ''
                        }, () => {
                            this.props.onChange(this.state.result)
                        })
                    }
                });
        } else {
            this.setState({
                result: [],
                isUploading: false,
                isUploaded: false,
                file: [],
                fileView: ''
            }, () => {
                this.props.onChange(this.state.result)
            })
        }
    }

    onChange = (e) => {

        let size = e.target.files[0].size;
        let size_in_kb = parseFloat(size) / 1024;

        if (size_in_kb <= this.state.limit) {

            //check file type 

            let type = e.target.files[0].type;
            if (allowed_ext.includes(type)) {

                // now check dimentions for photo & signature

                let img = new Image();
                var imgwidth = 0;
                var imgheight = 0;
                var maxwidth = this.state.dimentions.width;
                var maxheight = this.state.dimentions.height;
                img.src = URL.createObjectURL(e.target.files[0]);
                let files = e.target.files;
                img.onload = () => {
                    imgwidth = img.width;
                    console.log("FileUpload -> img.onload -> imgwidth", imgwidth)

                    imgheight = img.height;
                    console.log("FileUpload -> img.onload -> imgheight", imgheight)

                    if (maxwidth == 0 && maxheight === 0) {
                        this.setState({
                            fileView: img.src,
                            file: files,
                            error: ''
                        }, () => {
                            this.uploadFile()
                        })
                    } else {
                        if (imgwidth <= maxwidth && imgheight <= maxheight) {

                            this.setState({
                                fileView: img.src,
                                file: files,
                                error: ''
                            }, () => {
                                this.uploadFile()
                            })
                        } else {
                            this.setState({
                                error: `File dimentions must not exceed to the provided limit`,
                                file: [],
                                fileView: ''
                            })
                        }
                    }



                }
            } else {
                this.setState({
                    error: 'Only .jpg and .png are allowed. Wrong file type!!',
                    file: [],
                    fileView: ''
                })
            }

        } else {
            this.setState({
                error: `Please select a file below ${this.state.limit}KB`,
                file: [],
                fileView: ''
            })
        }


    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    } else if (resp.mime === 'application/pdf') {
                        file = 'https://png.pngtree.com/svg/20170509/5023fbf29c.png';
                    }
                    this.setState({
                        fileView: file
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                result: data,
                isUploaded: true,
                isUploading: true
            }, () => this.__getFile(this.state.result[0].file_id))
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    result: data,
                    isUploaded: true,
                    isUploading: true
                }, () => this.__getFile(this.state.result[0].file_id))
            }
        }
    }

    render() {
        let { classes } = this.props;
        const toolText = `Please upload .jpg / .png only. The maximum memory of the file should be below ${this.props.limit}KB`;
        return (
            <div className={classes.root}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {this.props.title}

                    &nbsp;
                    <span>
                        <Tooltip title={toolText}>
                            <HelpOutline
                                style={{
                                    fontSize: 15,
                                    color: 'grey'
                                }}
                            />
                        </Tooltip>
                    </span>

                </div>




                {this.state.error !== '' ? (
                    <div>
                        <Typography
                            variant="body2"
                            style={{ color: 'red' }}
                        >
                            {this.state.error}
                        </Typography>
                    </div>
                ) : null}
                <div className={classes.container}>
                    <div className={classes.photoHolder}
                        style={{ height: this.props.type === 'ADMISSION_SIGNATURE' ? 50 : 100 }}>
                        {this.state.isUploaded ? <img
                            src={this.state.fileView}
                            className={classes.uploadedFile}
                        /> : <UploadButton onChange={this.onChange} />}
                    </div>
                    {!this.state.isUploading ?
                        <div className={classes.uploaderDiv}>
                            {/* <input
                                className="form-control"
                                type="file"
                                accept={'image/jpeg,image/x-png'}
                                name="file_upload"
                                onChange={this.onChange}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.uploadFile}
                                style={{ borderRadius: 0, height: '43px' }}
                            >
                                Upload
                        </Button> */}
                        </div> :
                        <div className={classes.uploaderDiv}>
                            {!this.state.isUploaded ? <Typography
                                variant="caption"
                            >
                                <CircularProgress size={12} /> &nbsp; Uploading
                            </Typography> :
                                <a
                                    style={{ color: 'red', fontSize: '12px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 0px 3px 0px' }}
                                    onClick={this.deleteFile}
                                >
                                    <DeleteIcon style={{ fontSize: '12px' }} /> &nbsp; Delete
                        </a>
                            }
                        </div>
                    }
                </div>

            </div>
        )
    }
}

export default withStyles(styles)(FileUpload)


const PdfView = (props) => {

    if (props.show) {

        let i = '';
        if (Array.isArray(props.name) && props.name.length > 0) {
            if (props.name[0].hasOwnProperty('file_id')) {
                i = props.name[0].file_id.split('_').join(' ');
            }

        }

        return (
            <div style={{
                padding: '20px',
                display: 'flex',
                alignItems: 'center'
            }}>
                <PictureAsPdf
                    style={{ fontSize: 40 }}
                />
                <Typography
                    style={{ paddingLeft: '10px' }}
                >
                    {i}
                </Typography>
            </div>
        )
    } else {
        return null;
    }

}

const UploadButton = ({ onChange }) => {
    return (
        <div className={`uploadButton-new`}>
            <input
                className="hidden-form-input-new"
                type="file"
                accept={'image/jpeg,image/x-png'}
                name="file_upload"

                onChange={onChange}
            />
            <AddIcon />
            <div>Choose File</div>
        </div>
    )
}