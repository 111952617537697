import React, { Component } from 'react'
import { Typography, Grid, Button, FormControlLabel, Checkbox, CardContent, Card, CircularProgress } from '@material-ui/core'
import AlertDiv from '../Forms/AlertDiv'
import { green, blue } from '@material-ui/core/colors'
import { GetData, PostData } from '../../../api/service'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default class AdmissionLanding extends Component {

    state = {
        request_id: '',
        accept: '',
        reason: '',
        error: [],
        checked: false,
        bank_account: '',
        bank_branch: '',
        bank_ifsc: '',
        bank_name: '',
        micr_code: '',
        hostel: '',
        unique_id: '',
        new_unique_id: '',
        isLoaded: false,
        hasRequest: '',
        requestsList: [],
        alreadySubmitted: false,
        submittedRequest: {},
        final_major: {},
        course: '',
        stream: '',
        course_type: ''
    }

    checkAdmissionRequest = () => {
        GetData(`/${this.props.user.apikey}/getmyadmissionrequest-re`)
            .then((resp) => {


                // for no request
                if (resp.status === 'norequest') {
                    this.setState({
                        hasRequest: false,
                        isLoaded: true,
                        alreadySubmitted: false
                    })
                }

                // for active request
                if (resp.status === true) {
                    this.setState({
                        hasRequest: true,
                        isLoaded: true,
                        requestsList: resp.status_data,
                        final_major: resp.status_data[0].final_major,
                        hostel: resp.status_data[0].hostel,
                        unique_id: resp.status_data[0].unique_id,
                        course: resp.status_data[0].course,
                        stream: resp.status_data[0].stream,
                        course_type: resp.status_data[0].course_type,
                        alreadySubmitted: false
                    })
                }


                // for accepted request
                if (resp.status === 'found') {
                    this.setState({
                        hasRequest: true,
                        isLoaded: true,
                        submittedRequest: resp.status_data,
                        hostel: resp.status_data.hostel,
                        unique_id: resp.status_data.unique_id,
                        alreadySubmitted: true,

                    })
                }
            })
    }

    componentDidMount() {
        this.checkAdmissionRequest()
    }

    fetchData = () => {
        // 

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    submitRejection = () => {
        if (this.handleRejectValidation()) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.finalRejection()
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    }

    finalRejection = () => {
        PostData(`/${this.props.user.apikey}/submitmyrejection`, {
            request_id: this.state.request_id,
            accept: this.state.accept,
            reason: this.state.reason
        })
            .then((resp) => {
                if (resp == true) {
                    confirmAlert({
                        title: 'Successfully Rejected',
                        message: 'Your request has been successfully rejected.',
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => this.checkAdmissionRequest()
                            }
                        ]
                    });
                } else {
                    confirmAlert({
                        title: 'Error',
                        message: 'Something Went Wrong. Try again later',
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => this.checkAdmissionRequest()
                            }
                        ]
                    });
                }
            })
    }

    handleRejectValidation = () => {
        let isValid = true;
        let error = [];

        let { request_id, accept, reason } = this.state;

        if (request_id === '' || request_id === null) {
            isValid = false;
            error['request_id'] = 'This is compulsory field';
        } else {
            error['request_id'] = '';
        }


        if (accept === '' || accept === null) {
            isValid = false;
            error['accept'] = 'This is compulsory field';
        } else {
            error['accept'] = '';
        }


        if (reason === '' || reason === null) {
            isValid = false;
            error['reason'] = 'This is compulsory field';
        } else {
            error['reason'] = '';
        }


        this.setState({
            error
        })

        return isValid;

    }


    handleAcceptValidation = () => {
        let isValid = true;
        let error = [];

        let { request_id, accept, hostel, checked, unique_id } = this.state;

        if (request_id === '' || request_id === null) {
            isValid = false;
            error['request_id'] = 'This is compulsory field';
        } else {
            error['request_id'] = '';
        }


        if (accept === '' || accept === null) {
            isValid = false;
            error['accept'] = 'This is compulsory field';
        } else {
            error['accept'] = '';
        }

        if (unique_id === '' || unique_id === null) {
            isValid = false;
            error['unique_id'] = 'This is compulsory field';
        } else {
            error['unique_id'] = '';
        }


        if (hostel === '' || hostel === null) {
            isValid = false;
            error['hostel'] = 'This is compulsory field';
        } else {
            error['hostel'] = '';
        }


        if (checked === false || checked === null) {
            isValid = false;
            error['checked'] = 'You must agree to this undertaking';
        } else {
            error['checked'] = '';
        }

        console.log(error)


        this.setState({
            error
        })

        return isValid;

    }


    submitAcceptance = () => {
        if (this.handleAcceptValidation()) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.finalAcceptSubmit()
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    }


    finalAcceptSubmit = () => {
        PostData(`/${this.props.user.apikey}/submitmyacceptance`, {
            request_id: this.state.request_id,
            accept: this.state.accept,
            hostel: this.state.hostel,
            unique_id: this.state.unique_id
        })
            .then((resp) => {
                if (resp == true) {
                    confirmAlert({
                        title: 'Successfully Accepted',
                        message: 'You have successfully accepted the request. Now you must wait for confirmation from college',
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => this.checkAdmissionRequest()
                            }
                        ]
                    });
                } else {
                    confirmAlert({
                        title: 'Error',
                        message: 'Something Went Wrong. Try again later',
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => this.checkAdmissionRequest()
                            }
                        ]
                    });
                }
            })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    submitUniqueId = () => {
        if (this.state.new_unique_id !== '') {
            // submit now
            PostData(`/${this.props.user.apikey}/submituniqueid`, {
                unique_id: this.state.new_unique_id
            }).then((resp) => {
                if (resp != false) {
                    this.setState({
                        unique_id: resp
                    })
                }
            })

        }
    }

    render() {
        return (
            <div>



                {!this.state.isLoaded && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '200px'
                    }}
                >
                    <CircularProgress />
                    <br />
                    <Typography variant="caption">
                        Checking for Admission Requests, Please wait . . .
                    </Typography>
                </div>}



                {this.state.hasRequest === false && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '200px'
                    }}
                >
                    <Typography variant="h6">
                        No Request Found Yet !!
                    </Typography>
                    <Typography variant="caption">
                        Sorry !! There is no admission request for you now. Visit the site later to check again or "Refresh" now
                    </Typography>
                </div>}





                {this.state.isLoaded && this.state.hasRequest === true && this.state.alreadySubmitted == false && <div>
                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        Admission Panel
                    </Typography>


                    <AlertDiv
                        border={green[300]}
                        back={green[50]}
                    >
                        <b>Congratulations</b>, you got selected for the provisional admission in Bhawanipur Anchalik College for this session. Now, you need to choose whether or not you want to accept this offer.
                        Follow the steps below in order to accept/reject the offer.
                        <ul>
                            <li>Select the offer you want to choose</li>
                            <li>Select whether you want to accept it or not</li>
                            <li>If you want to reject the offer select "No", you have to provide the reason for rejecting the offer and submit it.</li>
                            <li>If you accept the offer, you have to fillup or verify the informations shown. Correct the informations if required.</li>
                            <li>Click on the "Final Submit" button to send confirmation from your side to the college</li>
                            <li>Now college will verify all the informations and provide you with an acknowledgement receipt with your roll number in it.</li>

                        </ul>

                        {/* For TDC Honours show the final Major that has been offered */}

                        {this.state.course === 'TDC' && this.state.course_type === 'HONOURS' && <React.Fragment>
                            <Typography variant="h6">
                                You have been offered <span style={{color: 'green'}}>{this.state.final_major.label !== undefined && this.state.final_major.label}</span> as Honours Subject. You can either accept this offer or reject it.
                            </Typography>
                        </React.Fragment>}



                        <Typography style={{ color: 'red' }}>College will have all the rights for accepting or rejecting your application.</Typography>
                    </AlertDiv>


                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: 700 }}
                            >
                                List of request received <span className="red-alert">*</span>
                            </Typography>
                            <div className="red-alert">{this.state.error['request_id']}</div>
                            <select
                                className="form-control"
                                type="text"
                                name="request_id"
                                required
                                value={this.state.request_id}
                                onChange={this.onChange}
                            >
                                <option value="">Select</option>
                                {Array.isArray(this.state.requestsList) && this.state.requestsList.map((el, index) =>
                                    <option key={index} value={el.request_id}>{el.request_type}</option>
                                )}

                            </select>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: 700 }}

                            >
                                Do you want to accept it ? <span className="red-alert">*</span>
                            </Typography>
                            <div className="red-alert">{this.state.error['accept']}</div>
                            <select
                                className="form-control"
                                type="text"
                                name="accept"
                                required
                                value={this.state.accept}
                                onChange={this.onChange}

                            >
                                <option value="">Select your answer</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>

                            </select>
                        </Grid>

                        {this.state.accept === 'NO' && <React.Fragment>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Reason for rejection <span className="red-alert">*</span>
                                </Typography>
                                <div className="red-alert">{this.state.error['reason']}</div>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="reason"
                                    required
                                    value={this.state.reason}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select your reason</option>
                                    <option value="I am not interested">I am not interested</option>
                                    <option value="I have already admitted in other college of my choice">I have already admitted in other college of my choice</option>
                                    <option value="Other">Other</option>
                                </select>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={this.submitRejection}
                                >
                                    Submit My Answer
                                </Button>
                            </Grid>
                        </React.Fragment>}





                    </Grid>


                    {this.state.accept === 'YES' && <React.Fragment>
                        <br />
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            Fill the following Details
                        </Typography>



                        <br />



                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Unique ID (Generated from DHE Portal) <a style={{ color: 'blue' }} target="__blank" href={`https://directorateofhighereducation.assam.gov.in/`}>https://directorateofhighereducation.assam.gov.in/</a> <span className="red-alert">*</span>
                                </Typography>

                                <input
                                    className="form-control"
                                    type="text"
                                    required
                                    name="unique_id"
                                    value={this.state.unique_id}
                                    onChange={this.onChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={3} md={3}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Do You Need Hostel Accomodation <span className="red-alert">*</span>
                                </Typography>
                                <div className="red-alert">{this.state.error['hostel']}</div>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="hostel"
                                    required
                                    value={this.state.hostel}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select One</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>

                                </select>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="red-alert">{this.state.error['checked']}</div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.checked}
                                            onChange={this.handleChange}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label="All the documents / informations I furnished by me are true to the best of my knowledge, if any discrepancies detected I shall be held responsible that deemed fit."
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={this.submitAcceptance}
                                >
                                    Final Submit
                                </Button>
                            </Grid>
                        </Grid>


                    </React.Fragment>}







                </div>}


                {/* Already Submitted */}

                {this.state.isLoaded && this.state.hasRequest === true && this.state.alreadySubmitted == true && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '300px'
                    }}
                >

                    <CheckCircleIcon
                        style={{
                            color: green[700],
                            fontSize: 60
                        }}
                    />
                    <Typography variant="h6">
                        Request Successfully Submitted !!
                    </Typography>

                    <Typography variant="caption" style={{ textAlign: 'center' }}>
                        {this.state.submittedRequest.is_admitted === 0 && `Waiting for verification`}
                        {this.state.submittedRequest.is_admitted === 1 && `Your Application has been accepted. Click the button below to download the Acknowledgement Receipt`}
                    </Typography>

                    {this.state.unique_id === '' || this.state.unique_id === null ? (
                        <div>
                            <Grid container>
                                <Grid item xs={12} sm={12} lg={8} md={8}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Unique ID (Generated from DHE Portal) <a style={{ color: 'blue' }} target="__blank" href={`https://directorateofhighereducation.assam.gov.in/`}>https://directorateofhighereducation.assam.gov.in/</a> <span className="red-alert">*</span>
                                    </Typography>

                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="new_unique_id"
                                        value={this.state.new_unique_id}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4} md={4} >
                                    <div style={{ marginTop: '41px' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"

                                            onClick={this.submitUniqueId}
                                        >
                                            Submit Unique ID
                                </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : <React.Fragment>
                            {this.state.submittedRequest.is_admitted === 1 && <Button
                                onClick={() => this.props.history.push(`/re-admission/ack`)}
                                variant="contained" style={{ backgroundColor: green[700], color: 'white' }}>
                                Download Acknowledgement Receipt
                                </Button>}
                        </React.Fragment>}



                    <br />


                </div>}
            </div>
        )
    }
}

