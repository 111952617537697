import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid, Divider } from '@material-ui/core';
import { GetData } from '../../../../api/service';
import ReactToPrint from 'react-to-print';
import './formprint.css'
import Barcode from 'react-barcode'
import Loading from '../Forms/Loading';
import AlertDiv from '../Forms/AlertDiv';
import { orange } from '@material-ui/core/colors';
import MyAdmissionLanding from '../Admission/MyAdmissionLanding';

const styles = theme => ({

})

class ViewForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoaded: false
        }

        this.buttonRef = React.createRef();

    }


    getInitialData = () => {
        GetData(`/${this.props.user.email}/hostelform/getactivestudentsingle`)
            .then((resp) => {
                //console.log(resp[0])
                if (Array.isArray(resp) && resp.length > 0 && resp[0].active_step==3) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }
                else {
                    this.props.history.replace('/hostel-home');
                }

            })
    }

    componentDidMount() {
        this.getInitialData();
        // GetData(`/${this.props.user.email}/hostelform/hasreachedstep`)
        //     .then((resp) => {
        //         if (resp) {
        //             this.getInitialData();
        //         } else {
        //             this.props.history.replace('/home');
        //         }
        //     })

    }


    render() {
        let { classes } = this.props;
        return (
            <div>

                {this.state.isLoaded && <MyAdmissionLanding
                    user={this.props.user}
                    data={this.state.data}
                    history={this.props.history}
                />}

                <div
                    style={{ textAlign: 'center' }}
                >
                    <AlertDiv
                        back={orange[100]}
                        border={orange[400]}
                    >
                        You can print this form or can save as pdf. Click on the button below
                    </AlertDiv>
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"

                            >
                                Print or Save
                        </Button>
                        }
                        ref={el => this.buttonRef = el}
                        content={() => this.componentRef}
                        pageStyle={{ margin: '10px' }}
                        copyStyles={true}
                    />
                </div>


                <br />
                <br />

                {this.state.isLoaded ? <AdmissionForm
                    ref={el => (this.componentRef = el)}
                    {...this.state.data}
                /> : null}


            </div>
        )
    }
}

export default withStyles(styles)(ViewForm);

class AdmissionForm extends Component {
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        return (
            <div className="admission-form">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.jpg'}
                                    style={{ width: '100px' }}
                                />
                            </td>

                            <td width="65%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    BHAWANIPUR ANCHALIK COLLEGE
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O-Bhawanipur, Dist-Barpeta, Assam, Pin-781352
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                  Hostel Admission Form - <b>2020-21</b>
                                </Typography>
                            </td>

                            <td width="15%" align="right">
                                <Barcode
                                    value={this.props.form_id}
                                    height={40}
                                    width={1.3}
                                    margin={0}
                                    displayValue={false}
                                />
                                <Typography
                                    variant="subtitle2"
                                >
                                    Form No. : <b>{this.props.form_id}</b> <br />


                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Name of the Applicant
                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Class Name
                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.class_name}
                                </Typography>
                            </td>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Roll No
                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.roll_no}
                                </Typography>
                            </td>

                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Contact No
                                </Typography>
                            </td>
                            <td width={`15%`} >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.phone}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Gender
                                </Typography>
                            </td>
                            <td >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.gender}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Marrital Status
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.marrital_status}
                                </Typography>
                            </td>
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Nationality
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.nationality}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Email id
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.email}
                                </Typography>
                            </td>

                        </tr>

                        <tr>

                        <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Admission Year
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.admission_year}
                                </Typography>
                            </td>

                        <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Differently abled
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.differently_abled}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Guardian's/Father Name
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_name}
                                </Typography>
                            </td>

                           

                            
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Father/Guardian's Phone
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_contact}
                                </Typography>
                            </td>
                        </tr>


                        

                    </tbody>
                </table>
                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            
                            <td width="25%" style={{ verticalAlign: 'top' }}>
                                <Typography
                                    style={{
                                        fontWeight: '18px',
                                        fontWeight: 500
                                    }}

                                >Present Address
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '12px', fontWeight: 400 }}
                                >
                                    Village/Town: {this.props.present_address.pre_village}<br />
                                        P.O: {this.props.present_address.pre_po}<br />
                                        Dist: {this.props.present_address.pre_dist}, State: {this.props.present_address.pre_state}<br />
                                        Pin: {this.props.present_address.pre_pin}, Contact: {this.props.present_address.pre_contact}
                                </Typography>
                            </td>

                            <td width="25%" style={{ verticalAlign: 'top' }}>
                                <Typography
                                    style={{
                                        fontWeight: '18px',
                                        fontWeight: 500
                                    }}

                                >Permanent Address
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '12px', fontWeight: 400 }}
                                >
                                    Village/Town: {this.props.permanent_address.per_village}<br />
                                        P.O: {this.props.permanent_address.per_po}<br />
                                        Dist: {this.props.permanent_address.per_dist}, State: {this.props.permanent_address.per_state}<br />
                                        Pin: {this.props.permanent_address.per_pin}, Contact: {this.props.permanent_address.per_contact}
                                </Typography>
                            </td>

                            <td width="25%" style={{ verticalAlign: 'top' }}>
                                <Typography
                                    style={{
                                        fontWeight: '18px',
                                        fontWeight: 500
                                    }}

                                >Local Guardians' Details
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '12px', fontWeight: 400 }}
                                >
                                    Name: {this.props.local_g}<br />
                                    Village/Town: {this.props.local_address.g_village}<br />
                                        P.O: {this.props.local_address.g_po}<br />
                                        Dist: {this.props.local_address.g_dist}, State: {this.props.local_address.g_state}<br />
                                        Pin: {this.props.local_address.g_pin}, Contact: {this.props.local_address.g_contact}<br />
                                        LAC: {this.props.permanent_address.per_lac}
                                </Typography>
                            </td>


                        </tr>
                    </tbody>
                </table>

                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Institution last attended
                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.last_institution}
                                </Typography>
                            </td>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Percentage of marks
                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.last_percentage}
                                </Typography>
                            </td>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                   Were you expelled from hostel or college previously ?
                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.expel_status}
                                </Typography>
                            </td>

                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                   Will you abide by hostel rules
                                </Typography>
                            </td>
                            <td width={`15%`} >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.hostel_rules}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Will you compensate any kind of damage to the hostel caused by you ?
                                </Typography>
                            </td>
                            <td >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.hostel_damage}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Were you an hostel boarder in the previous year ?
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.hostel_boarder}
                                </Typography>
                            </td>
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Did you clear your previous hostel dues (if any)
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.hostel_dues}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Do you have any proficiency in sports and games ?
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.sports_status}
                                </Typography>
                            </td>

                        </tr>

                        
                    </tbody>
                </table>
                <table className="table" width="100%">
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <Typography>
                                    Declaration from the Applicant
                                </Typography>



                                <Typography
                                    variant="body2"
                                >
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    I , <b>{this.props.name}</b> do hereby solemnly declare that I shall abide by the hostel rules and regulations. Further I declare that I shall not cause any kind of nuissance or disturbance or mental or physical suffering to any inmate of the hostel. I vow that if do such deeds as have been mentioned above I shall be liable to punishment deemed fit and proper to me.
                                </Typography>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'bottom'
                                    }}
                                >
                                    <div>
                                        <Typography
                                            variant="body2"
                                        >
                                            Date : {this.props.time_stamp}
                                    </Typography>
                                    </div>
                                    <div>
                                        <div className="signature-photo" >
                                            <ImageViewer
                                                data={this.props.signature}
                                            />
                                        </div>
                                        <Typography
                                            variant="body2"
                                            style={{ float: 'right' }}>
                                            Full Signature of the Applicant
                                                </Typography>
                                    </div>

                                </div>






                            </td>
                        </tr>

                    </tbody>

                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <Typography>
                                    Declaration of the Parent/Guardian
                                </Typography>



                                <Typography
                                    variant="body2"
                                >
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    I, <b>{this.props.father_name}</b>  parent/guardian of the above named student, do hereby declare that <b> {this.props.name} </b>will abide by the rules and regulations of the hostel. In case of violation of rules and regulations the college authority can consider any punishment deem fit and proper.
                                </Typography>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'bottom'
                                    }}
                                >
                                    <div>
                                        <Typography
                                            variant="body2"
                                        >
                                            Date : {this.props.time_stamp}
                                    </Typography>
                                    </div>
                                    <div>
                                        <div className="signature-photo" >
                                            <ImageViewer
                                                data={this.props.guardians_signature}
                                            />
                                        </div>
                                        <Typography
                                            variant="body2"
                                            style={{ float: 'right' }}>
                                            Full Signature of the Guardian
                                                </Typography>
                                    </div>

                                </div>
                                </td>
                        </tr>

                    </tbody> 
                </table>


                <table width="100%" className="table">
                    <tbody>
                        <tr><td colSpan="6">&nbsp;</td></tr>

                        

                        <tr>
                            <td colSpan="3">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Recommended / Refuse</b>
                                </Typography>
                            </td>

                            <td colSpan="3">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Selected / Rejected</b>
                                </Typography>
                            </td>
                            

                        </tr>
                        <tr>
                            <td colSpan="3">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Sign of Hostel Superintendent / Warden</b><br />
                                    Date : 
                                </Typography>
                            </td>
                            <td colSpan="3">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Principal</b>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div >
        )
    }
}


class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div
                style={{ height: '100%', width: '100%' }}
            >
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%', height: '100%' }}

                /> : <Loading />}
            </div>
        )
    }
}