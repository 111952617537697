import React, { Component } from 'react';
import './css/homepage.css';
import { Grid, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import InstructionsDiv from '../Components/Homepage/InstructionsDiv';
import { lightBlue, blue } from '@material-ui/core/colors';
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Navbar from '../Components/HostelAdmission/Navbar';
import BannerDiv from '../Components/HostelAdmission/BannerDiv';
import NewLogin from '../Components/HostelAdmission/Homepage/NewLogin';
import ShowMdFiles from '../Components/Essentials/ShowMdFiles';
import FooterDiv from '../Components/Homepage/FooterDiv';

class HomePageHostel extends Component {
  constructor(props) {
    super(props);
  }



  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
        />
        <BannerDiv />

       
        <div
          style={{
            backgroundColor: blue[400],
            minHeight: '300px',

          }}
        >
          <div className="cont">
            <Grid container spacing={24}>
              

              <Grid item xs={12} sm={12} lg={6} md={6}>


                <NewLogin
                  history={this.props.history}
                />
              </Grid>
            </Grid>
          </div>

        </div>

        <div className="main-body">

          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img
                src={`/images/hostel_notice.jpg`}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ShowMdFiles
                fileName={`contactUs`}
              />
            </Grid>
          </Grid>
        </div>

        {/* <div align="center" style={{backgroundColor: lightBlue[50], padding: '10vw'}}>
            <Typography
              variant="h4"
            >
              For Re-admission
            </Typography>
            <Typography
              variant="subheading"
            >
              Please follow the link below
            </Typography>


          <br />
            <br />

            <Button
              size="large"
              href="/reregistration"
              variant="outlined"
              color="primary"
            >
              Re-Admission 
            </Button>

          <br />
            <br />
        </div> */}

        <FooterDiv />
      </div>
    )
  }
}

export default HomePageHostel;