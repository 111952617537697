import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button, Grid } from '@material-ui/core';

import ReactToPrint from 'react-to-print';
import Loading from '../Forms/Loading';


import Barcode from 'react-barcode'
import { GetData } from '../../../api/service';
const styles = theme => ({

})

class AckReceipt extends Component {

    state = {
        data: this.props.data,
        isLoaded: true
    }

    componentDidMount() {

    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <h1>Admission Acknowledgement Receipt</h1>

                {this.state.isLoaded ? (
                    <Receipt
                        {...this.state.data}
                        ref={el => (this.componentRef = el)}
                    />
                ) : <Loading />}


                <br />
                <br />

                {this.state.isLoaded ? (

                    <div>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Acknowledgement Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />


                    </div>
                ) : null}
            </div>
        )
    }
}

export default withStyles(styles)(AckReceipt);

class Receipt extends Component {
    goodSemester = (sem) => {
        if (sem == 1) {
            return '1st';
        } else if (sem == 2) {
            return '2nd';
        } else if (sem == 3) {
            return '3rd';
        } else {
            return `${sem}th`;
        }
    }
    render() {
        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.jpg'}
                                    style={{ width: '100px' }}
                                />
                            </td>

                            <td width="65%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    BHAWANIPUR ANCHALIK COLLEGE
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O-Bhawanipur, Dist-Barpeta, Assam, Pin-781352
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Acknowledgement Receipt (Re-admission) <b>2020-21</b>
                                </Typography>
                            </td>

                            <td width="15%" align="right">

                                <Typography
                                    variant="subtitle2"
                                >
                                    Form No. : <b>{this.props.form_id}</b> <br />


                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <Typography gutterBottom variant="body2">
                    <strong>{this.props.name}</strong>, you have been admitted against your DHE Unique ID <strong>{this.props.unique_id}</strong> into {this.props.course}-{this.props.stream}- {this.goodSemester(this.props.semester)} {this.props.course === 'HS' ? `Year` : `Semester`} {this.props.course === 'TDC' ? ' - ' + this.props.course_type : null}. Your
                    Roll No is <strong>{this.props.roll_no}</strong>
                </Typography>

                <br />

                <table className="table">
                    <tbody>
                        <tr>
                            <td width="50%" align="center" style={{ padding: '5px' }}>
                                <Barcode
                                    value={this.props.roll_no}
                                    height={60}
                                    width={1.5}
                                    margin={0}
                                    displayValue={false}
                                />
                                <Typography
                                    variant="subtitle2"
                                >Roll No</Typography>
                                <Typography
                                    variant="h6"
                                >{this.props.roll_no}</Typography>
                            </td>

                            <td width="50%" align="center" style={{ padding: '5px' }}>
                                <Barcode
                                    value={this.props.unique_id}
                                    height={60}
                                    width={1.5}
                                    margin={0}
                                    displayValue={false}
                                />
                                <Typography
                                    variant="subtitle2"
                                >Unique ID</Typography>
                                <Typography
                                    variant="h6"
                                >{this.props.unique_id}</Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="80%">
                                <table className="table">

                                    <tbody>
                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Class
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.course}-{this.props.stream}- {this.goodSemester(this.props.semester)} {this.props.course === 'HS' ? `Year` : `Semester`} {this.props.course === 'TDC' ? ' - ' + this.props.course_type : null}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Roll No
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.roll_no}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Name
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.name}
                                                </Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Form No
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.form_id}
                                                </Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Unique ID
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.unique_id}
                                                </Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Father's Name
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.father_name}
                                                </Typography>
                                            </td>
                                        </tr>





                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Date of Admission
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.good_admission_date}
                                                </Typography>
                                            </td>
                                        </tr>



                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Fees Paid
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.amount_paid !=null && this.props.amount_paid !=0?this.props.amount_paid:'NIL'}
            </Typography>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </td>
                            <td>
                                <div className="passport-photo">
                                    <ImageViewer
                                        data={this.props.dp}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />


                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width="60%">
                                {this.props.course === 'HS' ? (

                                    <table width="100%" className="table">

                                        <tbody>
                                            <tr>
                                                <td colSpan="2">
                                                    <Typography style={{
                                                        fontWeight: '18px',
                                                        fontWeight: 500
                                                    }}>
                                                        Subjects taken
</Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                    >
                                                        Compulsory: {this.props.compulsory_1.label}, {this.props.compulsory_2.label}
                                                    </Typography>
                                                </td>

                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                    >
                                                        MIL: {this.props.mil.label}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <Grid container>
                                                        {this.props.optional_subjects.map((el, index) =>

                                                            <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                                                <Typography variant="body2" >
                                                                    {index + 1} : {el.label}
                                                                </Typography>
                                                            </Grid>

                                                        )}
                                                    </Grid>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>) : null}

                                {this.props.course === 'TDC' && this.props.semester == 3 ? (<table width="100%" className="table">

                                    <tbody>
                                        <tr>
                                            <td colSpan="4">
                                                <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                    Combination of Subject sougth for TDC 3rd Semester
</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            {this.props.course_type === 'HONOURS' && <td>
                                                <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                    Core Course (Honours)
</Typography>

                                                <Typography variant="body2">
                                                    {this.props.major_1.label}
                                                </Typography>

                                            </td>}

                                            {this.props.course_type === 'REGULAR' && <td style={{ verticalAlign: 'top' }}>
                                                <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                    Core Course (Regular)
</Typography>

                                                <Typography variant="body2">
                                                    1: {this.props.generic_1.label}
                                                </Typography>
                                                <Typography variant="body2">
                                                    2: {this.props.generic_2.label}
                                                </Typography>
                                                <Typography variant="body2">
                                                    3: {this.props.generic_3.label}
                                                </Typography>
                                            </td>}

                                            <td style={{ verticalAlign: 'top' }}>
                                                <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                    Skill Enhancement Course
</Typography>


                                                <Typography variant="body2">
                                                    {this.props.compulsory_1.label}
                                                </Typography>
                                            </td>

                                            {this.props.course_type === 'HONOURS' && <td style={{ verticalAlign: 'top' }}>
                                                <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                    Generic Elective Course
</Typography>


                                                <Typography variant="body2">
                                                    {this.props.generic_1.label}
                                                </Typography>

                                            </td>}
                                        </tr>
                                    </tbody>

                                </table>) : null}



                                {this.props.course === 'TDC' && this.props.semester == 5 ? (<table width="100%" className="table">

                                    <tbody>
                                        <tr>
                                            <td colSpan="4">
                                                <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                    Combination of Subject sougth for TDC 5th Semester
</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            {this.props.course_type === 'HONOURS' && <td>
                                                <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                    Major
</Typography>

                                                <Typography variant="body2">
                                                    {this.props.major_1.label}
                                                </Typography>

                                            </td>}

                                            {this.props.course_type === 'REGULAR' && <td style={{ verticalAlign: 'top' }}>
                                                <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                    General Subject
</Typography>

                                                <Typography variant="body2">
                                                    1: {this.props.generic_1.label}
                                                </Typography>
                                                <Typography variant="body2">
                                                    2: {this.props.generic_2.label}
                                                </Typography>

                                            </td>}




                                        </tr>
                                    </tbody>

                                </table>) : null}

                            </td>
                            <td width="40%">
                                <Typography
                                    variant="h6"


                                >
                                    Bank Details
                                </Typography>
                                <Typography
                                    variant="body2"

                                >
                                    Bank Name: {this.props.bank_name}<br />
                                        Branch: {this.props.bank_branch}<br />
                                        A/C No: {this.props.bank_account}<br />
                                        IFSC: {this.props.bank_ifsc}<br />
                                        MICR Code: {this.props.micr_code}
                                </Typography>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />

                <Typography variant="body2">
                    This acknowledgement is purely provisional and does not guarantee acceptance of your formal admission. If found any
                    fraudulant academic documents etc. as you uploaded in College Admission Portal then your admission is liable to be
                    cancelled. The admission is purely governed by the Rules and Notifications of Gauhati University/Assam Higher Secondary
                    Education Council/Govt. of Asssm.
                </Typography>

                <br />


                <Typography variant="body2" style={{ textAlign: 'center' }}>
                    <strong>Note</strong> : Since this is a system generated Receipt, Signature is not required.
                </Typography>



            </div>
        )
    }

}

class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div
                style={{ height: '100%', width: '100%' }}
            >
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%', height: '100%' }}

                /> : <Loading />}
            </div>
        )
    }
}