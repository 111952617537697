import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import { GetData, PostData } from '../api/service';
import { Grid, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import InstructionsDiv from '../Components/Homepage/InstructionsDiv';
import Login from '../auth_components/js/Login';
import NewLogin from '../Components/Homepage/NewLogin';
import BannerDiv from '../Components/BannerDiv';
import FooterDiv from '../Components/Homepage/FooterDiv';
import { lightBlue, blue } from '@material-ui/core/colors';
import ShowMdFiles from '../Components/Essentials/ShowMdFiles';
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class HomePage extends Component {
  constructor(props) {
    super(props);
  }



  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
        />
       

        <div
          style={{
            
            minHeight: '300px',
            backgroundImage: '/images/bread-new-1.png'
          }}
        >
          <div className="main-bg">
          <div className="cont">
            
            <Grid container spacing={16} alignItems="center">
              <Grid item xs={12} sm={12} lg={8} md={8}>
              <Typography
                  variant="h4"
                  style={{ color: "white", fontWeight: 500 }}
                  gutterBottom
                >
                  Registration for New Admission
              </Typography>

                <Typography
                  variant="body1"
                  style={{ color: 'white' }}
                >
                  If you have already registered for new-admission purpose, there is no need to register again. For students taking admission in 1st semester or 1st year must register before continue. Existing students can login to this portal and fill up the admission form.
              </Typography>

              <br />


              <Typography
                  variant="body1"
                  style={{ color: 'white' }}
                >
                  Read the following guidelines before continue.
              </Typography>
              <br />
              <Link to="/registration">
                  <Button color='primary' variant="contained"
                    // style={{
                    //   backgroundColor: 'white',
                    //   color: 'green',
                    //   borderRadius: 0
                    // }}
                  >
                    New Admission (New-Registration)
              </Button>
                </Link>

                &nbsp;


                <Link to="/reregistration">
                  <Button color='primary' variant="contained"
                    // style={{
                    //   backgroundColor: 'white',
                    //   color: 'green',
                    //   borderRadius: 0
                    // }}
                  >
                    Re-Admission
              </Button>
                </Link>

            
              
              </Grid>

              <Grid item xs={12} sm={12} lg={4} md={4}>


                <NewLogin
                  history={this.props.history}
                />
                <br />
                
              </Grid>
            </Grid>
            </div>
          </div>

        </div>
        <div className="main-body">
          <div style={{
            color: 'blue',
            textAlign: 'center',
            marginBottom: '10px'
          }}>


          </div>


          <br />

          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Typography
                variant="h5"
                style={
                  {
                    fontWeight: 400
                  }
                }

              >
                Follow these guidelines before continue
              </Typography>

             
             
              <ShowMdFiles
                fileName={`gettingStarted`}
              />

            </Grid>

           
          </Grid>



        </div>

        {/* <div
          style={{
            backgroundColor: blue[400],
            minHeight: '300px',

          }}
        >
          <div className="cont">
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} lg={6} md={6}>
                <Typography
                  variant="h5"
                  style={{ color: 'white' }}
                >
                  New Admission / Readmission Student Registration
              </Typography>

                <Typography
                  variant="body2"
                  style={{ color: 'white' }}
                >
                  For new users it is must that you read all the above mentioned insturctions before continue. Keep all the necessary details, files, photographs in handy, so that you can
                  upload in necessary fields. You must remember your user credentials generated after the first signup for future references.
              </Typography>

                <br />


                <Link to="/registration">
                  <Button size="small" variant="contained"
                    style={{
                      backgroundColor: 'white',
                      color: 'green',
                      borderRadius: 0
                    }}
                  >
                    New User (New-admission)
              </Button>
                </Link>

                &nbsp;&nbsp;

                
              </Grid>

              <Grid item xs={12} sm={12} lg={6} md={6}>


                <NewLogin
                  history={this.props.history}
                />
              </Grid>
            </Grid>
          </div>

        </div> */}

        

        

        <FooterDiv />
      </div>
    )
  }
}

export default HomePage;