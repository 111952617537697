import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles, Typography, Divider, Button } from "@material-ui/core";
import { GetData } from "../../../api/service";
import ReactToPrint from "react-to-print";
import Loading from "../Forms/Loading";
import queryString from "query-string";
import AlertDiv from "../Forms/AlertDiv";
import { green, blue } from "@material-ui/core/colors";
const styles = (theme) => ({});

class PaymentResponse extends Component {
  state = {
    data: [],
    isLoaded: false,
    status: "",
    message: "",
  };

  componentDidMount() {
    GetData(`/getpaymentreceipt`).then((resp) => {
      if (Array.isArray(resp) && resp.length > 0) {
        this.setState({
          data: resp[0],
          isLoaded: true,
        });
      } 
      // else {
      //   this.props.history.replace("/home");
      // }
    });

    const parsed = queryString.parse(this.props.history.location.search);

    this.setState({
      status: parsed.status,
      message: parsed.message,
    });
  }

  render() {
    let { classes } = this.props;
    return (
      <div>
        {this.state.status == "Success" && (
          <AlertDiv border={green[300]} back={green[50]}>
            <h3>Payment Successfully done</h3>
            <div>{this.state.message}</div>
          </AlertDiv>
        )}

        {this.state.status == "Failed" && (
          <AlertDiv border={green[300]} back={green[50]}>
            <h3>Failed !!!!</h3>
            <div>{this.state.message}</div>
          </AlertDiv>
        )}

        <h1>Payment Response</h1>

        {this.state.isLoaded ? (
          <Receipt
            {...this.state.data}
            ref={(el) => (this.componentRef = el)}
          />
        ) : (
          <Loading />
        )}

        <br />
        <br />

        {this.state.isLoaded ? (
          <div>
            <ReactToPrint
              trigger={() => (
                <Button variant="outlined" color="primary">
                  Print Receipt
                </Button>
              )}
              ref={(el) => (this.buttonRef = el)}
              content={() => this.componentRef}
              pageStyle={{ margin: "10px" }}
              copyStyles={true}
            />

            <Link to="/final">
              <Button variant="outlined" color="primary">
                Print Application Form
              </Button>
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(PaymentResponse);

class Receipt extends Component {
  render() {
    return (
      <div>
        <table width="100%">
          <tbody>
            <tr>
              <td width="20%">
                <img
                  alt="logo"
                  src={"/images/logo.jpg"}
                  style={{ width: "50%" }}
                />
              </td>

              <td width="60%" align="center">
                <Typography variant="h5">
                  Bhawanipur Anchalik College
                </Typography>
                <Typography variant="body2">
                  Bhawanipur , BARPETA - 781319
                </Typography>
                <Typography variant="body2">Assam</Typography>
                <Typography variant="body1">Payment Receipt</Typography>
              </td>

              <td width="20%" align="center">
                <Typography variant="subtitle2">
                  Form No. : <b>{this.props.form_id}</b> <br />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />

        <table className="table">
          <tbody>
            <tr>
              <td width="30%">
                <Typography variant="body2">Name</Typography>
              </td>

              <td>
                <Typography variant="body2">{this.props.name}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Form No</Typography>
              </td>

              <td>
                <Typography variant="body2">{this.props.form_id}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Transaction Status</Typography>
              </td>

              <td>
                <Typography variant="body2">Success</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Receipt No</Typography>
              </td>

              <td>
                <Typography variant="body2">{this.props.receipt_no}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Date of Payment</Typography>
              </td>

              <td>
                <Typography variant="body2">{this.props.date}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Amount</Typography>
              </td>

              <td>
                <Typography variant="body2">
                  {this.props.amount_paid}
                </Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Mode of Payment</Typography>
              </td>

              <td>
                <Typography variant="body2">{this.props.mop}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Transaction ID</Typography>
              </td>

              <td>
                <Typography variant="body2">
                  {this.props.transaction_id}
                </Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Order No</Typography>
              </td>

              <td>
                <Typography variant="body2">
                  {this.props.order_id}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
