import React, { Component } from 'react'
import { withStyles, Typography, Grid, Card, CardHeader, CardContent, Avatar, Button } from '@material-ui/core';
import AccountBox from '@material-ui/icons/AccountBox'
import Assignment from '@material-ui/icons/Assignment'
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import { blue, green, red } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
        background: `url('/images/bg-banner.jpg')`,

        backgroundSize: 'fit',
        backgrodRepeat: 'no-repeat',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
})

class BannerDiv extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>




                <Grid container>
                    <Grid item lg={4} md={4} xs={6} sm={6} style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Card style={{ width: '200px',  color: 'white', backgroundColor: 'rgba(0,0,0,0.6)' }} raised>
                            <CardContent>

                                <AccountBox style={{
                                    color: blue[400],
                                    fontSize: 50
                                }} />

                                <Typography
                                    color="inherit"
                                    variant="body2"
                                >
                                    Log In
                                </Typography>
                                <Typography color="inherit" variant="caption">
                                   Fill-up the form and submit.
                                </Typography>
                            </CardContent>


                        </Card>
                    </Grid>


                    
                </Grid>

               
            </div>
        )
    }
}

export default withStyles(styles)(BannerDiv)