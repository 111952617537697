import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import { GetData, PostData } from '../api/service';
import { Grid, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import InstructionsDiv from '../Components/Homepage/InstructionsDiv';
import Login from '../auth_components/js/Login';
import NewLogin from '../Components/Homepage/NewLogin';
import BannerDiv from '../Components/BannerDiv';
import FooterDiv from '../Components/Homepage/FooterDiv';
import { lightBlue, blue } from '@material-ui/core/colors';
import ShowMdFiles from '../Components/Essentials/ShowMdFiles';
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

class FileListPage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        files: [
            {
                name: "Principal's Notice",
                link: "/List/2021-22/AdmissionNotice2021.pdf"
            },
            {
                name: "Merit List- Arabic (Hons)",
                link: "/List/2021-22/ARABIC_Hons.pdf"
            },
            {
                name: "Merit List- Assamese (Hons)",
                link: "/List/2021-22/ASSAMESE_Hons.pdf"
            },
            {
                name: "Merit List- Botany (Hons)",
                link: "/List/2021-22/BOTANY_Hons.pdf"
            },
            {
                name: "Merit List- Chemistry (Hons)",
                link: "/List/2021-22/CHEM_Hons.pdf"
            },
            {
                name: "Merit List- Economics (Hons)",
                link: "/List/2021-22/ECONOMICS_Hons.pdf"
            },
            {
                name: "Merit List- Education (Hons)",
                link: "/List/2021-22/EDUCATION_Hons.pdf"
            },
            {
                name: "Merit List- History (Hons)",
                link: "/List/2021-22/HISTORY_Hons.pdf"
            },
            {
                name: "Merit List- Mathematics (Hons)",
                link: "/List/2021-22/MATH_Hons.pdf"
            },
            {
                name: "Merit List- Philosophy (Hons)",
                link: "/List/2021-22/PHILOSOPHY_Hons.pdf"
            },
            {
                name: "Merit List- Physics (Hons)",
                link: "/List/2021-22/PHYSICS_Hons.pdf"
            },
            {
                name: "Merit List- Political Science (Hons)",
                link: "/List/2021-22/POLSC_Hons.pdf"
            },
            {
                name: "Merit List- Zoology (Hons)",
                link: "/List/2021-22/ZOOLOGY_Hons.pdf"
            },
            
        ]
    }

    componentDidMount() {

    }


    render() {
        return (
            <div>
                <Navbar
                    history={this.props.history}
                />
                <BannerDiv />





                <div className="main-body">

                    <Typography variant="h5" gutterBottom>
                        Important File List
          </Typography>

                    <table className="table table-sm table-border">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>File</td>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(this.state.files) && this.state.files.map((el, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    
                                    <td>  <a style={{color: 'blue', display: 'flex', alignItems: 'center'}} target="__blank" href={el.link}><PictureAsPdfIcon /> &nbsp; {el.name}</a></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>



                <FooterDiv />
            </div>
        )
    }
}

export default FileListPage;