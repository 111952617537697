import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';
import PreviousExamination from './PreviousExamination';
import BestOfThree from './BestOfThree';
import SubjectsCombo from './SubjectsCombo';
import { blue } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileUpload from './FileUpload';

import allStates from './../../../helper/IndianStates.json'

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})

const occupations = [
    "Govt. Service",
    "Non-Govt. Service",
    "Farmer",
    "Others"
]

class FormOne extends Component {

    state = {
        name: this.props.user.name,
        class_id: '',
        class_name: '',
        unique_id: '',
        aadhar_no: "",
        pan_no: "",
        course: '',
        stream: '',
        session: '',

        session_id: '',
        is_free_admission: 0,
        phone: this.props.user.phone,
        email: this.props.user.email,
        father_name: '',
        mother_name: '',
        gender: '',
        marrital_status: '',
        date_of_birth: this.props.user.date_of_birth,
        nationality: 'Indian',
        religion: '',
        caste: '',
        quota: '',
        state: 'Assam',
        blood_group: '',
        present_address: [],
        permanent_address: [],
        local_address: [],
        local_g: '',
        extra_curr: '',
        exam_passed: [],
        best_subjects: [],
        last_marks: [],
        total_marks: '',
        marks_obtained: '',
        percentage: '',
        certificate: [],
        marksheet: [],
        bank_name: '',
        bank_account: '',
        bank_ifsc: '',
        bank_branch: '',
        micr_code: '', whatsapp_no: this.props.user.phone,
        father_qualification: '',
        mother_qualification: '',
        father_profession: '',
        mother_profession: '',
        father_contact: '',
        mother_contact: '',
        differently_abled: '',
        computer_course: '',
        hostel: '',
        library: '',
        pre_village: '', pre_po: '', pre_dist: '', pre_state: '', pre_pin: '', pre_contact: this.props.user.phone,
        per_village: '', per_po: '', per_dist: '', per_state: '', per_pin: '', per_contact: this.props.user.phone, per_lac: '',
        g_village: '', g_po: '', g_dist: '', g_state: '', g_pin: '', g_contact: ''
    }

    componentDidMount() {

        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
                ...data[0].present_address,
                ...data[0].permanent_address,
                ...data[0].local_address,
                whatsapp_no:data[0].phone
            })
        }

    }

    toggleAddCh = () => {

        this.setState({
            add_ch: !this.state.add_ch
        }, () => {
            if (this.state.add_ch) {

                this.setState({
                    per_village: this.state.pre_village,
                    per_po: this.state.pre_po,
                    per_dist: this.state.pre_dist,
                    per_state: this.state.pre_state,
                    per_pin: this.state.pre_pin,
                    per_contact: this.state.pre_contact,

                })
            } else {
                this.setState({
                    permanent_address: []
                })
            }
        })
    }

    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0],
                    ...data[0].present_address,
                ...data[0].permanent_address,
                ...data[0].local_address,
                whatsapp_no:data[0].phone
                })
            }
        }

    }


    onNewChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    sentTotalData = (data) => {
        this.setState({
            ...data
        })
    }

    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        // if(e.target.name ==='pre_village' || e.target.name ==='pre_po' || e.target.name ==='pre_dist' || e.target.name ==='pre_state'
        // || e.target.name ==='pre_pin'|| e.target.name ==='pre_contact'){
        //     let present_address = this.state.present_address;
        //     let x = e.target.name;
        //     present_address.push({x: e.target.value});
        //     this.setState({
        //         present_address:present_address
        //     })
        // }

        // if( e.target.name ==='per_village'|| e.target.name ==='per_po'
        // || e.target.name ==='per_dist'|| e.target.name ==='per_state'|| e.target.name ==='per_pin'|| e.target.name ==='per_contact'|| e.target.name ==='per_lac'
        // ){
        //     let permanent_address = this.state.permanent_address;
        //     let x = e.target.name;
        //     permanent_address.push({x: e.target.value});
        //     this.setState({
        //         permanent_address:permanent_address
        //     })
        // }

        // if(e.target.name ==='g_village'|| e.target.name ==='g_po'|| e.target.name ==='g_dist'|| e.target.name ==='g_state'|| e.target.name ==='g_state'
        // || e.target.name ==='g_pin'|| e.target.name ==='g_contact'){
        //     let local_address = this.state.local_address;
        //     let x = e.target.name;
        //     local_address.push({x: e.target.value});
        //     this.setState({
        //         local_address:local_address
        //     })
        // }
    }

    onSubmit = (e) => {
        e.preventDefault();
        let present_address = {
            pre_village: this.state.pre_village,
            pre_po: this.state.pre_po,
            pre_dist: this.state.pre_dist,
            pre_state: this.state.pre_state,
            pre_pin: this.state.pre_pin,
            pre_contact: this.state.pre_contact,
        }
        let permanent_address = {
            per_village: this.state.per_village,
            per_po: this.state.per_po,
            per_dist: this.state.per_dist,
            per_state: this.state.per_state,
            per_pin: this.state.per_pin,
            per_contact: this.state.per_contact,
            per_lac: this.state.per_lac,

        }
        let local_address = {
            g_village: this.state.g_village,
            g_po: this.state.g_po,
            g_dist: this.state.g_dist,
            g_state: this.state.g_state,
            g_pin: this.state.g_pin,
            g_contact: this.state.g_contact,
        }
        this.setState({
            present_address,
            permanent_address,
            local_address
        }, () => { this.props.setData(this.state) })
        //let d = this.state;
        // console.log(this.state)
        //newly added
        //this.props.setData(d);

        // confirmAlert({
        //     title: 'Confirm to submit',
        //     message: 'Are you sure to do this.',
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: () => this.props.setData(d)
        //         },
        //         {
        //             label: 'No'
        //         }
        //     ]
        // });

    }

    sentTotalData = (data) => {
        this.setState({
            total_marks: data.over_all,
            marks_obtained: data.obtained,
            percentage: data.percentage
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h5"
                    >
                        Application Form
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>

                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Student Details
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Write your Unique ID (Generated from DHE Portal) <span className="red-alert">*</span> 
                                    </Typography>
                                    
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="unique_id"
                                        value={this.state.unique_id}
                                        onChange={this.onChange}
                                    />
                                    <div style={{fontSize: 11}}>Keep screenshot,you need to upload it.</div>
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        AADHAR No (if available)
                                    </Typography>
                                    
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="aadhar_no"
                                        value={this.state.aadhar_no}
                                        onChange={this.onChange}
                                    />
                                    
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        PAN Card No (if available)
                                    </Typography>
                                    
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pan_no"
                                        value={this.state.pan_no}
                                        onChange={this.onChange}
                                    />
                                    
                                </Grid>

                                
                            </Grid>
                        </CardContent>
                    </Card>

                    <br />



                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Personal Details
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Full Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        required
                                        readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Gender <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="gender"
                                        required
                                        value={this.state.gender}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Marital Status <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="marrital_status"
                                        required
                                        value={this.state.marrital_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Unmarried">Unmarried</option>
                                        <option value="Married">Married</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Religion <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="religion"
                                        required
                                        value={this.state.religion}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="HINDUISM">HINDUISM</option>
                                        <option value="ISLAM">ISLAM</option>
                                        <option value="CHRISTIANITY">CHRISTIANITY</option>
                                        <option value="SIKHISM">SIKHISM</option>
                                        <option value="BUDDHISM">BUDDHISM</option>
                                        <option value="JAINISM">JAINISM</option>
                                        <option value="OTHER">OTHER</option>

                                    </select>
                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Date of Birth <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="date"
                                        required
                                        readOnly
                                        name="date_of_birth"
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Nationality <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="nationality"
                                        value={this.state.nationality}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Domicile State <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="state"
                                        required
                                        value={this.state.state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>

                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Caste <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="caste"
                                        required
                                        value={this.state.caste}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="GENERAL">GENERAL</option>
                                        <option value="ST(H)">ST(H)</option>
                                        <option value="ST(P)">ST(P)</option>
                                        <option value="SC">SC</option>
                                        <option value="OBC">OBC/MOBC</option>

                                    </select>
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Mobile No <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="phone"
                                        readOnly
                                        required
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Whatsapp No
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="whatsapp_no"


                                        value={this.state.whatsapp_no}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Email <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="email"
                                        readOnly
                                        required
                                        value={this.state.email}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Co-Curricular Activity
                                    </Typography>

                                    <input
                                        className="form-control"
                                        type="text"

                                        name="extra_curr"
                                        value={this.state.extra_curr}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Computer Course Passed (if any)
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="computer_course"
                                        value={this.state.computer_course}
                                        onChange={this.onChange}
                                    />
                                </Grid>



                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Persion with Disability (PWD)  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="differently_abled"
                                        required
                                        value={this.state.differently_abled}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>


                                    </select>

                                </Grid>

                                {/* <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Do You Need Hostel Accomodation <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="hostel"
                                        required
                                        value={this.state.hostel}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>

                                    </select>

                                </Grid> */}
                            </Grid>

                        </CardContent>
                    </Card>
                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Parent's Details
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Father's Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="father_name"
                                        value={this.state.father_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Qualification
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="father_qualification"
                                        value={this.state.father_qualification}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Profession
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="father_profession"
                                        value={this.state.father_profession}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Father's Contact No.
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="father_contact"
                                        value={this.state.father_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Mother's Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="mother_name"
                                        value={this.state.mother_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Qualification
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="mother_qualification"
                                        value={this.state.mother_qualification}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Profession
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="mother_profession"
                                        value={this.state.mother_profession}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No.
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="mother_contact"
                                        value={this.state.mother_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                            </Grid>



                        </CardContent>
                    </Card>

                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Present Address
                            </Typography>


                            <Grid container spacing={16}>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_village"
                                        value={this.state.pre_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.O<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_po"
                                        value={this.state.pre_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Dist<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_dist"
                                        value={this.state.pre_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State<span className="red-alert">*</span>
                                    </Typography>

                                    <select
                                        className="form-control"
                                        type="text"
                                        name="pre_state"
                                        required
                                        value={this.state.pre_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="pre_pin"
                                        value={this.state.pre_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_contact"
                                        value={this.state.pre_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Permanent Address
                                        <span>
                                    <Typography
                                        variant="caption"
                                    >
                                        <input type="checkbox" checked={this.state.add_ch} onClick={this.toggleAddCh} /> same as Present address
                                            </Typography>
                                </span>
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_village"
                                        value={this.state.per_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.O<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_po"
                                        value={this.state.per_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Dist<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_dist"
                                        value={this.state.per_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State<span className="red-alert">*</span>
                                    </Typography>


                                    <select
                                        className="form-control"
                                        type="text"
                                        name="per_state"
                                        required
                                        value={this.state.per_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="per_pin"
                                        value={this.state.per_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="per_contact"
                                        value={this.state.per_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        LAC <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_lac"
                                        value={this.state.per_lac}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Local Guardian's Details
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Name
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"


                                        name="local_g"
                                        value={this.state.local_g}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="g_village"
                                        value={this.state.g_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.O
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="g_po"
                                        value={this.state.g_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Dist
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="g_dist"
                                        value={this.state.g_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State
                                    </Typography>

                                    <select
                                        className="form-control"
                                        type="text"
                                        name="g_state"

                                        value={this.state.g_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="g_pin"
                                        value={this.state.g_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="g_contact"
                                        value={this.state.g_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>



                        <CardContent>


                            <Grid container spacing={16}>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: blue[700]
                                        }}

                                    >
                                        Bank Details
                                    </Typography>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Name of the Bank <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="bank_name"
                                        required

                                        value={this.state.bank_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Branch <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="bank_branch"

                                        required
                                        value={this.state.bank_branch}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Account Number <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="bank_account"
                                        required

                                        value={this.state.bank_account}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        IFSC Code<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="bank_ifsc"

                                        required
                                        value={this.state.bank_ifsc}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        MICR Code
                               </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="micr_code"


                                        value={this.state.micr_code}
                                        onChange={this.onChange}
                                    />
                                </Grid>


















                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>

                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormOne)