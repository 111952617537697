import React, { Component } from "react";
import {
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  CardContent,
  Card,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import AlertDiv from "../Forms/AlertDiv";
import { green, blue, red } from "@material-ui/core/colors";
import { GetData, PostData } from "../../../api/service";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import queryString from "query-string";

export default class PayNowPage extends Component {
  state = {
    receipts: [],
    isLoaded: false,
    status: false,
    message: "",
  };

  checkAdmissionRequest = () => {
    GetData(`/getunpaidreceipts`).then((resp) => {
      this.setState({
        receipts: resp,
        isLoaded: true,
      });
    });

    const parsed = queryString.parse(this.props.history.location.search);
    this.setState({
      status: parsed.message ? true: false,
      message: parsed.message,
    });
  };

  componentDidMount() {
    this.checkAdmissionRequest();
    //this.loadScript();
  }

  // loadScript = () => {
  //   const script = document.createElement("script");

  //   script.src = "https://checkout.razorpay.com/v1/checkout.js";
  //   script.async = true;

  //   document.body.appendChild(script);
  // };

  // payNow = (order) => {
  //   if (order != null) {
  //     var rzp1 = new window.Razorpay(order);
  //     rzp1.open();
  //   }
  // };
  render() {
    return (
      <div>
        {!this.state.isLoaded && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <CircularProgress />
            <br />
            <Typography variant="caption">
              Checking Please wait . . .
            </Typography>
          </div>
        )}

        {this.state.receipts.length === 0 && this.state.isLoaded == true && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <Typography variant="h6">No Receipts Found Yet !!</Typography>
            <Typography variant="caption">
              Sorry !! There is no payment request for you now. Visit the site
              later to check again or "Refresh" now.
            </Typography>
          </div>
        )}


        {this.state.status == true && (
          <AlertDiv border={red[300]} back={red[50]}>
            <h3>Cancelled !!!!</h3>
            <div>{this.state.message}</div>
          </AlertDiv>
        )}

        {this.state.isLoaded == true && (
          <div>
            <Typography variant="h5" gutterBottom>
              Admission Panel
            </Typography>

            <AlertDiv border={green[300]} back={green[50]}>
              <b>Congratulations</b>, you got selected for the provisional
              admission in Bhawanipur Anchalik College for this session. Now you
              have to make the following payment in order to complete the
              process.
              <ul>
                <li>
                  You must not press go back button while on the payment page
                </li>
                <li>
                  You payment will be processed through this panel and you will
                  receive the admission receipt after completing the payment.
                </li>
                <li>
                  If you have any issue related to payment, you can contact the
                  office directly.
                </li>
              </ul>
              <br />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order No</TableCell>
                    <TableCell>Receipt No</TableCell>
                    <TableCell>Amount To be Paid</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(this.state.receipts) &&
                    this.state.receipts.map((el, index) => (
                      <TableRow key={index}>
                        <TableCell>{el.order_id}</TableCell>
                        <TableCell>{el.receipt_no}</TableCell>
                        <TableCell>Rs. {el.amount_paid}</TableCell>
                        <TableCell>
                          <>
                          {el.order_request && <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
  <input type="hidden" name="key_id" value={el.order_request.key}/>
  <input type="hidden" name="amount" value={el.order_request.amount}/>
  <input type="hidden" name="order_id" value={el.order_request.order_id}/>
  <input type="hidden" name="name" value={el.order_request.name} />
  <input type="hidden" name="description" value=""/>
  <input type="hidden" name="image" value={el.order_request.image}/>
  <input type="hidden" name="prefill[name]" value={el.order_request.prefill.name}/>
  <input type="hidden" name="prefill[contact]" value={el.order_request.prefill.contact}/>
  <input type="hidden" name="prefill[email]" value={el.order_request.prefill.email}/>
  
  <input type="hidden" name="callback_url" value={el.order_request.callback_url}/>
  <input type="hidden" name="cancel_url" value={'https://admission.bacollege.ac.in/paynow?status=true&message=Last Transaction was cancelled by user'}/>
  <button className="ant-btn ant-btn-primary">Pay Now</button>
</form>}
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </AlertDiv>
          </div>
        )}
      </div>
    );
  }
}
