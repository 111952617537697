import React, { Component } from "react";
import {
  withStyles,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  InputLabel,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
  Paper,
} from "@material-ui/core";
import PreviousExamination from "./PreviousExamination";
import BestOfThree from "./BestOfThree";
import SubjectsCombo from "./SubjectsCombo";
import { blue, lightGreen, orange, green } from "@material-ui/core/colors";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import FileUpload from "./FileUpload";
import AlertDiv from "./AlertDiv";
import { GetData } from "../../../api/service";
import Grade from "@material-ui/icons/Grade";
import Sel from "react-select";
import MultiSubject from "./MultiSubject";

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});

class EducationalForm extends Component {
  state = {
    course: "",
    stream: "",
    course_type: "",
    semester: "",
    samarth_roll_no: "",
    email: this.props.user.email,
    exam_passed: [],
    best_subjects: [],
    last_marks: [],
    total_marks: "",
    marks_obtained: "",
    percentage: "",

    errors: [],
    subject_list: [],
    f_subject_list: [],
    optional_subjects: [],
    rc_list: [],
    aec_list: [],
    major_1: {},
    major_2: {},
    major_3: {},
    compulsory_1: {},
    compulsory_2: {},
    compulsory_3: {},
    generic_1: {},
    generic_2: {},
    generic_3: {},
    mil: {},
    comp_1: {},
    compulsory_list: [],
    generic_list: [],
    mil_list: [],
    major_list: [],
    f_major_list: [],
    f_generic_list: [],
    subject_comb: "",
    isLoaded: false,
    isDisabled: false,
    has_gap: "",
    gap_admission: "",
    gap_course: "",
    gap_institute: "",
    gap_reason: "",
    gap_roll_no: "",
  };

  __getSubjects = () => {
    GetData(
      `/${this.state.course}/${this.state.stream}/getsubjectcombinationbycourse`
    ).then((resp) => {
      //console.log(resp)
      this.setState({
        subject_list: resp,
      });
    });
  };

  componentDidMount() {
    let data = this.props.data;

    if (Array.isArray(data) && data.length > 0) {
      this.setState(
        {
          ...data[0],
          course:
            data[0].course === "" || data[0].course === null
              ? "HS"
              : data[0].course,
          stream:
            data[0].stream === "" || data[0].stream === null
              ? "ARTS"
              : data[0].stream,
          semester:
            data[0].semester === "" || data[0].semester === null
              ? 1
              : data[0].semester,
        },
        () => {
          // if (Array.isArray(this.props.data[0].best_subjects) && this.props.data[0].best_subjects.length == 0) {
          //     let bs = [];
          //     if (this.state.course === 'TDC' && this.state.stream === 'SCIENCE') {
          //         bs = [{ "subject": "Physics", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "Chemistry", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "Mathematics/Biology", "total_marks": "100", "marks_secured": "" }]
          //         this.setState({
          //             best_subjects: bs
          //         })
          //     } else if (this.state.course === 'TDC' && this.state.stream === 'ARTS') {
          //         bs = [{ "subject": "", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "", "total_marks": "100", "marks_secured": "" }]
          //         this.setState({
          //             best_subjects: bs
          //         })
          //     }
          // }

          // if (Array.isArray(this.props.data[0].last_marks) && this.props.data[0].last_marks.length == 0) {
          //     let lm = [];
          //     if (this.state.course === 'HS') {
          //         lm = [{ "subject": "MIL/Alt English", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "General Mathematics", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "English", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "General Science", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "Social Science", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "Elective (Adv. Maths, Hindi, Sanskrit)", "total_marks": "100", "marks_secured": "" }];
          //     } else {
          //         lm = [{ "subject": "", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "", "total_marks": "100", "marks_secured": "" },
          //         { "subject": "", "total_marks": "100", "marks_secured": "" }]
          //     }
          //     this.setState({
          //         last_marks: lm,
          //         total_marks: 0,
          //         marks_obtained: 0,
          //         percentage: 0,

          //     })
          // }
          this.__getData();
        }
      );
    }
  }
  __getData = (type = 0) => {
    GetData(
      `/${this.state.course}/${this.state.stream}/getadmissionsubject-re`
    ).then((resp) => {
      console.log("🚀 ~ EducationalForm ~ ).then ~ resp:", resp);
      //console.log("EducationalForm -> __getData -> resp", resp)
      //console.log(resp)
      // filter major subjects

      let stream = this.state.stream;
      let course = this.state.course;
      let semester = this.state.semester;

      if (this.state.course === "TDC") {
        let subjects = resp; // all subjects

        // 1 - Compulsory Subjects - 1 or 2
        // 2 - MIL Subjets
        // 3 - Major Preference Subject
        // 4 - Generic Preference Subject

        let comp = subjects.filter((el) => el.sub_type === "COMPULSORY"); // Compulsory list
        // console.log("EducationalForm -> __getData -> comp", comp)
        let maj = subjects.filter((el) => el.sub_type === "HC"); // major list
        let mil = subjects.filter((el) => el.sub_type === "MIL"); // mil list
        let aec = subjects.filter((el) => el.sub_type === "SE"); // mil list
        let gen = subjects.filter(
          (el) =>
            el.sub_type === "HG" ||
            el.sub_type === "RG" ||
            el.sub_type === "OPTIONAL"
        ); // generic list
        let rc = subjects.filter((el) => el.sub_type === "RE"); // RC list

        // fetch the compulsory subjects

        if (course == "TDC" && stream == "COMMERCE" && semester == 5) {
          comp = subjects.filter(
            (el) =>
              el.sub_type === "COMPULSORY" &&
              ["COM-HC-5016", "COM-HC-5026"].includes(el.sub_code)
          ); // Compulsory list

          gen = subjects.filter((el) => el.sub_type === "DSE"); // generic list
        }

        if (course == "TDC" && stream == "COMMERCE" && semester == 3) {
          comp = subjects.filter(
            (el) =>
              el.sub_type === "COMPULSORY" &&
              ["BCM030204", "BCM030304"].includes(el.sub_code)
          ); // Compulsory list
          maj = subjects.filter((el) => el.sub_type === "CORE");
          gen = subjects.filter(
            (el) => el.sub_type === "MDC" && ["MDC030403"].includes(el.sub_code)
          ); // generic list
          aec = subjects.filter(
            (el) =>
              el.sub_type === "SE" &&
              ["SEC030603", "SEC030703"].includes(el.sub_code)
          );
        }

        if (
          course == "TDC" &&
          (stream == "ARTS" || stream == "SCIENCE") &&
          semester == 3
        ) {
          maj = subjects.filter((el) => el.sub_type === "CORE");
          gen = subjects.filter((el) => el.sub_type === "MDC");
        }

        let comp_1 = {};
        let comp_2 = {};
        let comp_3 = {};

        if (Array.isArray(comp) && comp.length > 0) {
          if (comp.length === 1) {
            comp_1 = comp[0];
          }

          if (comp.length === 2) {
            comp_1 = comp[0];
            comp_2 = comp[1];
          }

          if (comp.length >= 3) {
            comp_1 = comp[0];
            comp_2 = comp[1];
            comp_3 = comp[2];
          }
        }

        this.setState(
          {
            major_list: maj,
            generic_list: gen,
            compulsory_list: comp,
            mil_list: mil,
            aec_list: aec,
            rc_list: rc,
            compulsory_1: comp_1,
            compulsory_2: comp_2,
            compulsory_3: comp_3,
            f_major_list: maj,
            f_generic_list: gen,
            comp_1: comp_1,
            isLoaded: true,
          },
          () => {
            this.__getSubjects();

            if (this.props.edit) {
              let data = this.props.data;
              if (Array.isArray(data) && data.length > 0) {
                let major_1 = data[0].major_1;
                let major_2 = data[0].major_2;
                let major_3 = data[0].major_3;
                let generic_1 = data[0].generic_1;
                let generic_2 = data[0].generic_2;
                let generic_3 = data[0].generic_3;
                let mil = data[0].mil;
                let compulsory_1 = data[0].compulsory_1;

                if (type === 1) {
                  major_1 = "";
                  major_2 = "";
                  major_3 = "";
                  generic_1 = "";
                  generic_2 = "";
                  generic_3 = "";
                  mil = "";
                  compulsory_1 = "";
                }

                //console.log(generic_1)
                this.setState(
                  {
                    major_1: major_1,
                    major_2: major_2,
                    major_3: major_3,
                    //compulsory_1: compulsory_1,
                    generic_1: generic_1,
                    generic_2: generic_2,
                    generic_3: generic_3,
                    mil: mil,
                    optional_subjects: data[0].optional_subjects,
                    subject_comb: data[0].subject_comb,
                  },
                  () => {
                    this.setSubjectFilter();
                  }
                );
              }
            }
          }
        );
      } else if (this.state.course === "HS") {
        let subjects = resp; // all subjects

        let r = subjects.filter((el) => el.course === course);
        let r1 = r; // major
        /* if (r.length > 0) {
                        r.map((el, index) => {
                            let s = el.stream;
                            let s1 = s.filter(el1 => el1.value === stream);
                            if (s1.length > 0) {
                                r1.push(el);
                            }
                        })
                    } */

        //optional subjects
        let r2 = subjects.filter((el) => el.sub_type === "OPTIONAL");
        let comp = subjects.filter((el) => el.sub_type === "COMPULSORY"); // compulsory
        let mil = subjects.filter((el) => el.sub_type === "MIL"); // mil

        // fetch the compulsory subjects

        let comp_1 = {};
        let comp_2 = {};
        let comp_3 = {};

        if (Array.isArray(comp) && comp.length > 0) {
          if (comp.length === 1) {
            comp_1 = comp[0];
          }

          if (comp.length === 2) {
            comp_1 = comp[0];
            comp_2 = comp[1];
          }

          // if (comp.length >= 3) {
          //     comp_1 = comp[1];
          //     comp_2 = comp[0];
          //     comp_3 = comp[2];
          // }
        }

        this.setState(
          {
            mil_list: mil,
            compulsory_list: comp,
            compulsory_1: comp_1,
            compulsory_2: comp_2,
            subject_list: r2,
            f_subject_list: r2,
            isLoaded: true,
          },
          () => {
            this.__getSubjects();
            if (this.props.edit) {
              let data = this.props.data;
              if (Array.isArray(data) && data.length > 0) {
                let op = data[0].optional_subjects;
                if (type === 1) {
                  op = [];
                }
                this.setState({
                  mil: data[0].mil,
                  //compulsory_1: data[0].compulsory_1,
                  optional_subjects: op,
                  subject_comb: data[0].subject_comb,
                });
              }
            }
          }
        );
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      let data = this.props.data;

      if (Array.isArray(data) && data.length > 0) {
        this.setState({
          ...data[0],
        });
      }
    }
  }

  onNewChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChangePhoto = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "subject_comb") {
      let subject_list = this.state.subject_list;
      let r = subject_list.filter((el) => el.id === parseFloat(e.target.value));
      if (Array.isArray(r) && r.length > 0) {
        this.setState({
          optional_subjects: r[0]["subjects"],
        });
      }
    }

    if (
      e.target.name === "course" ||
      e.target.name === "stream" ||
      e.target.name === "course_type" ||
      e.target.name == "semester"
    ) {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          this.__getData(1);
        }
      );
    }
  };

  setSubjectData = (name, data) => {
    this.setState({
      [name]: data,
    });
  };

  setSubjectFilter = () => {
    let r2 = [];
    let major_list = this.state.major_list;
    let major_1 = this.state.major_1;
    let major_2 = this.state.major_2;

    r2 = major_list.filter((el) => {
      if (major_1 !== null && major_2 !== null) {
        if (el.value !== major_1.value && el.value !== major_2.value) {
          return el;
        }
      }
      if (major_1 !== null && major_2 == null) {
        if (el.value !== major_1.value) {
          return el;
        }
      }

      if (major_1 == null && major_2 !== null) {
        if (el.value !== major_2.value) {
          return el;
        }
      }
      if (major_1 == null && major_2 == null) {
        return el;
      }
    });

    this.setState({
      f_major_list: r2,
    });

    // for generic list

    let r3 = [];
    let generic_list = this.state.generic_list;
    let generic_1 = this.state.generic_1;
    let generic_2 = this.state.generic_2;

    r3 = generic_list.filter((el) => {
      if (generic_1 !== null && generic_2 !== null) {
        if (el.value !== generic_1.value && el.value !== generic_2.value) {
          return el;
        }
      }
      if (generic_1 !== null && generic_2 == null) {
        if (el.value !== generic_1.value) {
          return el;
        }
      }

      if (generic_1 == null && generic_2 !== null) {
        if (el.value !== generic_2.value) {
          return el;
        }
      }
      if (generic_1 == null && generic_2 == null) {
        return el;
      }
    });

    this.setState({
      f_generic_list: r3,
    });
  };

  onSubChange = (name, e) => {
    this.setState(
      {
        [name]: e,
      },
      () => {
        let nn = 0;
        if (this.state.course === "TDC") {
          this.setSubjectFilter();
        } else if (this.state.course === "HS") {
          nn = 4;
          if (name === "optional_subjects") {
            let v = this.state.optional_subjects;
            if (v.length >= nn) {
              this.setState({
                f_subject_list: [],
              });
            } else {
              this.setState({
                f_subject_list: this.state.subject_list,
              });
            }
          }
        }
      }
    );
  };

  handleValidation = () => {
    let {
      major_1,
      major_2,
      major_3,
      compulsory_1,
      compulsory_2,
      compulsory_3,
      semester,
      generic_1,
      generic_2,
      generic_3,
      mil,
      samarth_roll_no,
      course,
      stream,
      course_type,
      optional_subjects,
      exam_passed,
      best_subjects,
      last_marks,
      errors,
    } = this.state;
    let isValid = true;

    if (course === "HS") {
      if (compulsory_1 !== null) {
        if (Object.entries(compulsory_1).length === 0) {
          isValid = false;

          errors["compulsory_1"] = "Please fill up this field";
        } else {
          errors["compulsory_1"] = "";
        }
      } else {
        isValid = false;
        errors["compulsory_1"] = "Please fill up this field";
      }

      // if (compulsory_2 !== null) {

      //     if (Object.entries(compulsory_2).length === 0) {
      //         isValid = false;

      //         errors['compulsory_2'] = 'Please fill up this field';
      //     } else {
      //         errors['compulsory_2'] = '';
      //     }
      // } else {
      //     isValid = false;
      //     errors['compulsory_2'] = 'Please fill up this field';
      // }

      if (mil !== null && mil !== undefined) {
        if (Object.entries(mil).length === 0) {
          isValid = false;

          errors["mil"] = "Please fill up this field";
        } else {
          errors["mil"] = "";
        }
      } else {
        isValid = false;

        errors["mil"] = "Please fill up this field";
      }

      if (Array(optional_subjects) && optional_subjects.length < 3) {
        isValid = false;
        errors["optional_subjects"] = "You have to fill at least 3 subjects";
      } else {
        errors["optional_subjects"] = "";
      }
    }

    if (
      course === "TDC" &&
      (stream == "ARTS" || stream == "SCIENCE") &&
      semester == 3
    ) {
      if (major_1 !== null) {
        if (Object.entries(major_1).length === 0) {
          isValid = false;
          errors["major_1"] = "Please fill up this field";
        } else {
          errors["major_1"] = "";
        }
      } else {
        isValid = false;
        errors["major_1"] = "Please fill up this field";
      }

      if (major_2 !== null) {
        if (Object.entries(major_2).length === 0) {
          isValid = false;
          errors["major_2"] = "Please fill up this field";
        } else {
          errors["major_2"] = "";
        }
      } else {
        isValid = false;
        errors["major_2"] = "Please fill up this field";
      }

      if (major_3 !== null) {
        if (Object.entries(major_3).length === 0) {
          isValid = false;
          errors["major_3"] = "Please fill up this field";
        } else {
          errors["major_3"] = "";
        }
      } else {
        isValid = false;
        errors["major_3"] = "Please fill up this field";
      }

      if (generic_1 !== null) {
        if (Object.keys(generic_1).length === 0) {
          isValid = false;
          errors["generic_1"] = "Please fill up this field";
        } else {
          errors["generic_1"] = "";
        }
      } else {
        isValid = false;
        errors["generic_1"] = "Please fill up this field";
      }

      if (generic_2 !== null) {
        if (Object.keys(generic_2).length === 0) {
          isValid = false;
          errors["generic_2"] = "Please fill up this field";
        } else {
          errors["generic_2"] = "";
        }
      } else {
        isValid = false;
        errors["generic_2"] = "Please fill up this field";
      }
    }

    if (course === "TDC" && stream == "ARTS" && semester == 5) {
      if (course_type === "HONOURS") {
        if (major_1 !== null) {
          if (Object.entries(major_1).length === 0) {
            isValid = false;

            errors["major_1"] = "Please fill up this field";
          } else {
            errors["major_1"] = "";
          }
        } else {
          isValid = false;
          errors["major_1"] = "Please fill up this field";
        }
      }

      if (course_type === "REGULAR") {
        if (generic_1 !== null) {
          if (Object.keys(generic_1).length === 0) {
            isValid = false;
            errors["generic_1"] = "Please fill up this field";
          } else {
            errors["generic_1"] = "";
          }
        } else {
          isValid = false;
          errors["generic_1"] = "Please fill up this field";
        }

        if (generic_2 !== null) {
          if (Object.keys(generic_2).length === 0) {
            isValid = false;
            errors["generic_2"] = "Please fill up this field";
          } else {
            errors["generic_2"] = "";
          }
        } else {
          isValid = false;
          errors["generic_2"] = "Please fill up this field";
        }

        if (generic_3 !== null) {
          if (Object.keys(generic_3).length === 0) {
            isValid = false;
            errors["generic_3"] = "Please fill up this field";
          } else {
            errors["generic_3"] = "";
          }
        } else {
          isValid = false;
          errors["generic_3"] = "Please fill up this field";
        }
      }
    }

    if (course == "TDC" && semester == 3) {
      if (samarth_roll_no == "" || samarth_roll_no == null) {
        isValid = false;
        errors["samarth_roll_no"] = "Please fill up this field";
      }else{
        errors["samarth_roll_no"] = ""
      }
    }

    if (course === "TDC" && stream == "COMMERCE" && semester == 3) {
      if (compulsory_1 !== null) {
        if (Object.entries(compulsory_1).length === 0) {
          isValid = false;

          errors["compulsory_1"] = "Please fill up this field";
        } else {
          errors["compulsory_1"] = "";
        }
      } else {
        isValid = false;
        errors["compulsory_1"] = "Please fill up this field";
      }

      if (compulsory_2 !== null) {
        if (Object.entries(compulsory_2).length === 0) {
          isValid = false;

          errors["compulsory_2"] = "Please fill up this field";
        } else {
          errors["compulsory_2"] = "";
        }
      } else {
        isValid = false;
        errors["compulsory_2"] = "Please fill up this field";
      }

      if (major_1 !== null) {
        if (Object.keys(generic_1).length === 0) {
          isValid = false;
          errors["major_1"] = "Please fill up this field";
        } else {
          errors["major_1"] = "";
        }
      } else {
        isValid = false;
        errors["major_1"] = "Please fill up this field";
      }

      if (generic_1 !== null) {
        if (Object.keys(generic_1).length === 0) {
          isValid = false;
          errors["generic_1"] = "Please fill up this field";
        } else {
          errors["generic_1"] = "";
        }
      } else {
        isValid = false;
        errors["generic_1"] = "Please fill up this field";
      }

      if (generic_2 !== null) {
        if (Object.keys(generic_2).length === 0) {
          isValid = false;
          errors["generic_2"] = "Please fill up this field";
        } else {
          errors["generic_2"] = "";
        }
      } else {
        isValid = false;
        errors["generic_2"] = "Please fill up this field";
      }
    }

    if (course === "TDC" && stream == "COMMERCE" && semester == 5) {
      if (compulsory_1 !== null) {
        if (Object.entries(compulsory_1).length === 0) {
          isValid = false;

          errors["compulsory_1"] = "Please fill up this field";
        } else {
          errors["compulsory_1"] = "";
        }
      } else {
        isValid = false;
        errors["compulsory_1"] = "Please fill up this field";
      }

      if (compulsory_2 !== null) {
        if (Object.entries(compulsory_2).length === 0) {
          isValid = false;

          errors["compulsory_2"] = "Please fill up this field";
        } else {
          errors["compulsory_2"] = "";
        }
      } else {
        isValid = false;
        errors["compulsory_2"] = "Please fill up this field";
      }

      if (generic_1 !== null) {
        if (Object.keys(generic_1).length === 0) {
          isValid = false;
          errors["generic_1"] = "Please fill up this field";
        } else {
          errors["generic_1"] = "";
        }
      } else {
        isValid = false;
        errors["generic_1"] = "Please fill up this field";
      }

      if (generic_2 !== null) {
        if (Object.keys(generic_2).length === 0) {
          isValid = false;
          errors["generic_2"] = "Please fill up this field";
        } else {
          errors["generic_2"] = "";
        }
      } else {
        isValid = false;
        errors["generic_2"] = "Please fill up this field";
      }
    }

    // for HS newly added
    // if (this.state.subject_comb === null || this.state.subject_comb === 0 || this.state.subject_comb === '') {
    //     isValid = false;
    //     errors['subject_comb'] = 'Please fill up this field'
    // } else {
    //     errors['subject_comb'] = '';
    // }

    // if (Array.isArray(exam_passed) && exam_passed.length === 0) {
    //     isValid = false;
    //     errors['exam_passed'] = 'Please fill up this field'
    // } else {
    //     errors['exam_passed'] = '';
    // }

    // if (Array.isArray(last_marks) && last_marks.length === 0) {
    //     isValid = false;
    //     errors['last_marks'] = 'Please fill up this field'
    // } else {
    //     errors['last_marks'] = '';
    // }

    // if (course === 'TDC') {
    //     if (Array.isArray(best_subjects) && best_subjects.length === 0) {
    //         isValid = false;
    //         errors['best_subjects'] = 'Please fill up this field'
    //     } else {
    //         errors['best_subjects'] = '';
    //     }
    // }

    this.setState({
      errors,
    });
    //console.log(isValid);
    return isValid;
  };

  onSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state)

    if (this.handleValidation()) {
      let d = {
        email: this.state.email,
        course: this.state.course,
        stream: this.state.stream,
        semester: this.state.semester,
        total_marks: this.state.total_marks,
        marks_obtained: this.state.marks_obtained,
        percentage: this.state.percentage,
        course_type: this.state.course_type,
        exam_passed: this.state.exam_passed,
        best_subjects: this.state.best_subjects,
        last_marks: this.state.last_marks,
        subject_comb: this.state.subject_comb,
        samarth_roll_no: this.state.samarth_roll_no,

        major_1: this.state.major_1,
        major_2: this.state.major_2,
        major_3: this.state.major_3,
        compulsory_1: this.state.compulsory_1,
        compulsory_2: this.state.compulsory_2,
        compulsory_3: this.state.compulsory_3,
        mil: this.state.mil,
        generic_1: this.state.generic_1,
        generic_2: this.state.generic_2,
        generic_3: this.state.generic_3,
        optional_subjects: this.state.optional_subjects,

        is_free_admission: this.state.is_free_admission,
        has_gap: this.state.has_gap,
        gap_admission: this.state.gap_admission,
        gap_course: this.state.gap_course,
        gap_institute: this.state.gap_institute,
        gap_reason: this.state.gap_reason,
        gap_roll_no: this.state.gap_roll_no,
      };
      //console.log(d)
      //let d = this.state;
      //newly added
      this.props.setData(d);
      // confirmAlert({
      //     title: 'Confirm to submit',
      //     message: 'Are you sure to do this.',
      //     buttons: [
      //         {
      //             label: 'Yes',
      //             onClick: () => this.props.setData(d)
      //         },
      //         {
      //             label: 'No'
      //         }
      //     ]
      // });
    }
  };

  showData(value) {
    if (Array.isArray(value) && value.length > 0) {
      let new_arr = value.map((el, index) => el.label);

      return new_arr.join(",");
    } else {
      return "";
    }
  }

  sentTotalData = (data) => {
    this.setState({
      total_marks: data.over_all,
      marks_obtained: data.obtained,
      percentage: data.percentage,
    });
  };

  showMePapers = (subject) => {
    if (subject !== null) {
      if (Object.keys(subject).length > 0) {
        return (
          <div>
            <AlertDiv back={orange[100]} border={orange[400]}>
              <div style={{ fontSize: 13 }}>Available Papers</div>

              <ul>
                {Array.isArray(subject.papers) &&
                  subject.papers.map((el, index) => {
                    if (el.semester == this.state.semester) {
                      return (
                        <li style={{ fontSize: 12 }} key={index}>
                          {el.paper_title}
                        </li>
                      );
                    }
                  })}
              </ul>
            </AlertDiv>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  render() {
    let { classes } = this.props;
    let i = [];
    let subject_list = this.state.subject_list;
    if (Array.isArray(subject_list)) {
      i = subject_list.map((el, index) => {
        return (
          <MenuItem value={el.id} key={index}>
            {this.showData(el.subjects)}
          </MenuItem>
        );
      });
    }

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h5">Educational Details</Typography>
          <Typography>
            All <span className="red-alert">*</span> marked fields are mandatory
          </Typography>

          <br />
          <Paper raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent style={{ minHeight: "150vh;auto" }}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Course <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="course"
                    required
                    value={this.state.course}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="HS">HS - Higher Secondary</option>
                    <option value="TDC">TDC - Under Graduate </option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2">
                    Stream <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="stream"
                    required
                    value={this.state.stream}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="ARTS">ARTS</option>
                    <option value="SCIENCE">SCIENCE</option>
                    <option value="COMMERCE">COMMERCE</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Semester/Year <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="semester"
                    required
                    value={this.state.semester}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {[2, 3, 5].map((el, index) => (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </Grid>

                {this.state.course === "TDC" ? (
                  <Grid item xs={12} sm={12} lg={3} md={3}>
                    <Typography variant="subtitle2">
                      Course Type <span className="red-alert">*</span>
                    </Typography>
                    <select
                      className="form-control"
                      type="text"
                      name="course_type"
                      required
                      value={this.state.course_type}
                      onChange={this.onChange}
                    >
                      <option value="">Couse type</option>
                      <option value="HONOURS">HONOURS (HC)/Major</option>
                      <option value="REGULAR">REGULAR (RC)/General</option>
                    </select>
                  </Grid>
                ) : null}


                {this.state.course == "TDC" && this.state.semester == 3 && <>
                  <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Samarth Roll No <span className="red-alert">*</span>
                  </Typography>
                  <input
                    className="form-control"
                    type="text"
                    name="samarth_roll_no"
                    required
                    value={this.state.samarth_roll_no}
                    onChange={this.onChange}
                  />
                   
                </Grid>
                </>}
              </Grid>
              <br />

              {this.state.course === "TDC" ? (
                <div>
                  <AlertDiv back={orange[100]} border={orange[400]}>
                    <Typography variant="subtitle2">
                      <Grade style={{ fontSize: 10 }} /> Important points to be
                      noted before you choose the subjects:
                    </Typography>

                    <ul>
                      <li>
                        <Typography>
                          Fill up the subjects accordingly as choosed at the
                          time of admission
                        </Typography>
                      </li>
                    </ul>
                  </AlertDiv>
                </div>
              ) : null}

              {this.state.course === "TDC" &&
              (this.state.stream == "ARTS" || this.state.stream == "SCIENCE") &&
              this.state.semester == 3 ? (
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Subjects taken for TDC 3rd Semester: {this.state.semester}{" "}
                      ({this.state.stream} {this.props.course_type}){" "}
                      <span className="red-alert">*</span>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                      <CardHeader title={`Core Course`} subheader={`CC`} />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["major_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["major_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Core Course <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_major_list}
                              value={this.state.major_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(this, "major_1")}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["major_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["major_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Minor 1 <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_major_list}
                              value={this.state.major_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(this, "major_2")}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["major_3"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["major_3"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Minor 2 <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_major_list}
                              value={this.state.major_3}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(this, "major_3")}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${orange[700]}` }}>
                      <CardHeader
                        title={`Skill Enhancement Course`}
                        subheader={`SE`}
                      />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Skill Enhancement Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.aec_list}
                              value={this.state.generic_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={false}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_1"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${green[700]}` }}>
                      <CardHeader
                        title={`Multi Disciplinary Course`}
                        subheader={`MDC`}
                      />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Multi Disciplinary Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_generic_list}
                              value={this.state.generic_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_2"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}

              {this.state.course === "TDC" &&
              this.state.stream == "COMMERCE" &&
              this.state.semester == 3 ? (
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Subjects taken for TDC 3rd Semester: {this.state.semester}{" "}
                      ({this.state.stream} {this.props.course_type}){" "}
                      <span className="red-alert">*</span>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                      <CardHeader title={`Core Course`} subheader={`CC`} />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["major_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["major_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Core Course <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_major_list}
                              value={this.state.major_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(this, "major_1")}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["compulsory_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["compulsory_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Compulsory 1 <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_major_list}
                              value={this.state.compulsory_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "compulsory_1"
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["compulsory_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["compulsory_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Compulsory 2 <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_major_list}
                              value={this.state.compulsory_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "compulsory_2"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${orange[700]}` }}>
                      <CardHeader
                        title={`Skill Enhancement Course`}
                        subheader={`SE`}
                      />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Skill Enhancement Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.aec_list}
                              value={this.state.generic_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={false}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_1"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${green[700]}` }}>
                      <CardHeader
                        title={`Multi Disciplinary Course`}
                        subheader={`MDC`}
                      />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Multi Disciplinary Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.f_generic_list}
                              value={this.state.generic_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_2"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}

              {this.state.course === "TDC" &&
              this.state.stream == "ARTS" &&
              this.state.semester == 5 ? (
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Subjects taken for TDC 5th Semester: {this.state.semester}{" "}
                      ({this.state.stream} {this.props.course_type}){" "}
                      <span className="red-alert">*</span>
                    </Typography>
                  </Grid>

                  {this.state.course_type === "HONOURS" && (
                    <Grid item xs={12} sm={12} lg={4} md={4}>
                      <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                        <CardHeader
                          title={`Major Subject`}
                          subheader={`select`}
                        />
                        <Divider />
                        <div style={{ padding: "10px" }}>
                          <Grid container spacing={24}>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["major_1"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["major_1"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Select Major Subject{" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.f_major_list}
                                value={this.state.major_1}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "major_1"
                                )}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                  )}

                  {this.state.course_type === "REGULAR" && (
                    <Grid item xs={12} sm={12} lg={4} md={4}>
                      <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                        <CardHeader
                          title={`General Subject`}
                          subheader={`Select`}
                        />
                        <Divider />
                        <div style={{ padding: "10px" }}>
                          <Grid container spacing={24}>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["generic_1"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["generic_1"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Regular Elective Subject{" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.rc_list}
                                value={this.state.generic_1}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "generic_1"
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["generic_2"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["generic_2"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Regular Generic Subject{" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.generic_list}
                                value={this.state.generic_2}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "generic_2"
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                              {this.state.errors["generic_3"] !== "" ? (
                                <div>
                                  <Typography
                                    variant="caption"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["generic_3"]}
                                  </Typography>
                                </div>
                              ) : null}

                              <Typography variant="subtitle2">
                                Skill Enhancement Subject{" "}
                                <span className="red-alert">*</span>
                              </Typography>
                              <Sel
                                options={this.state.aec_list}
                                value={this.state.generic_3}
                                isLoading={!this.state.isLoaded}
                                isClearable={true}
                                isSearchable={true}
                                onChange={this.onSubChange.bind(
                                  this,
                                  "generic_3"
                                )}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              ) : null}

              {this.state.course === "HS" ? (
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Subjects Taken for HS Course{" "}
                      <span className="red-alert">*</span>
                    </Typography>

                    <div>
                      <AlertDiv back={green[100]} border={green[400]}>
                        <Typography variant="subtitle2">
                          <Grade style={{ fontSize: 10 }} /> English &amp;
                          Environmental Studies is compulsory for all HS Courses
                        </Typography>

                        {this.state.stream === "ARTS" && (
                          <Typography variant="subtitle2">
                            <Grade style={{ fontSize: 10 }} /> You cannot take
                            Adv. Assamsese and Arabic at the same time
                          </Typography>
                        )}
                      </AlertDiv>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    {this.state.errors["compulsory_1"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["compulsory_1"]}
                        </Typography>
                      </div>
                    ) : null}

                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Compulsory Subject <span className="red-alert">*</span>
                    </Typography>
                    <Sel
                      options={this.state.compulsory_list}
                      value={this.state.compulsory_1}
                      isLoading={!this.state.isLoaded}
                      isClearable={false}
                      isSearchable={true}
                      onChange={this.onSubChange.bind(this, "compulsory_1")}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={12} lg={4} md={4}>
                                        {this.state.errors['compulsory_1'] !== '' ? (

                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['compulsory_1']}
                                                </Typography>
                                            </div>

                                        ) : null}


                                        <Typography
                                            variant="subtitle2"
                                            style={{ fontWeight: 700 }}
                                        >
                                            Compulsory Subject <span className="red-alert">*</span>
                                        </Typography>
                                        <Sel
                                            options={this.state.compulsory_list}
                                            value={this.state.compulsory_2}
                                            isLoading={!this.state.isLoaded}
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={this.onSubChange.bind(this, 'compulsory_2')}
                                        />

                                    </Grid> */}

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    {this.state.errors["mil"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["mil"]}
                        </Typography>
                      </div>
                    ) : null}

                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      MIL Subject <span className="red-alert">*</span>
                    </Typography>
                    <Sel
                      options={this.state.mil_list}
                      value={this.state.mil}
                      isLoading={!this.state.isLoaded}
                      isClearable={true}
                      isSearchable={true}
                      onChange={this.onSubChange.bind(this, "mil")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    {/*this.state.errors['subject_comb'] !== '' ? (
 
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.state.errors['subject_comb']}
                                                </Typography>
                                            </div>
 
                                        ) : null*/}

                    {/* <FormControl variant="outlined" required className={classes.formControl}>
                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Select One Combination <span className="red-alert">*</span>
                                        </Typography>
                                            
                                            <Select
                                                value={this.state.subject_comb}
                                                onChange={this.onChange}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={200}
                                                        name="subject_comb"

                                                    />
                                                }
                                                required
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {i}
                                            </Select>
                                        </FormControl> */}

                    {this.state.errors["optional_subjects"] !== "" ? (
                      <div>
                        <Typography variant="caption" style={{ color: "red" }}>
                          {this.state.errors["optional_subjects"]}
                        </Typography>
                      </div>
                    ) : null}

                    {this.state.isLoaded ? (
                      <MultiSubject
                        subjects={this.state.f_subject_list}
                        nos={4}
                        setData={this.setSubjectData.bind(
                          this,
                          "optional_subjects"
                        )}
                        data={this.state.optional_subjects}
                        edit={this.props.edit}
                      />
                    ) : (
                      `Loading Combinations`
                    )}
                  </Grid>
                </Grid>
              ) : null}

              {this.state.course === "TDC" &&
              this.state.stream == "COMMERCE" &&
              this.state.semester == 5 ? (
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Subjects taken for TDC 5th Semester Commerce:{" "}
                      {this.state.semester} ({this.state.stream}{" "}
                      {this.props.course_type}){" "}
                      <span className="red-alert">*</span>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                      <CardHeader
                        title={`Compulsory Course`}
                        subheader={`CC`}
                      />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["compulsory_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["compulsory_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Compulsory Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.compulsory_list}
                              value={this.state.compulsory_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "compulsory_1"
                              )}
                            />

                            <br />

                            {this.state.errors["compulsory_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["compulsory_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Compulsory Course{" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.compulsory_list}
                              value={this.state.compulsory_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "compulsory_2"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${orange[700]}` }}>
                      <CardHeader title={`DSE Papers`} subheader={`DSE`} />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              DSE 1<span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.generic_list}
                              value={this.state.generic_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={false}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_1"
                              )}
                            />

                            <br />

                            {this.state.errors["generic_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              DSE 2<span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.generic_list}
                              value={this.state.generic_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={false}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_2"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
            </CardContent>

            <CardActions className={classes.cardActions}>
              <Button type="button" onClick={this.props.back}>
                Previous
              </Button>
              <Button
                variant="contained"
                className={classes.buttonSubmit}
                type="submit"
              >
                Save &amp; Proceed to next step
              </Button>
            </CardActions>
          </Paper>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(EducationalForm);
