import React, { Component } from 'react'
import { withStyles, Typography, Chip } from '@material-ui/core';
import AlertDiv from '../Forms/AlertDiv';
import { orange } from '@material-ui/core/colors';
import { GetData } from '../../../api/service';

const styles = theme => ({

})

class FinalSubmitForm extends Component {
  state = {
    email: ''
  }

  componentDidMount() {
    // check for status update

    GetData(`/${this.props.email}/admissionform/haspaymentdone`)
      .then((resp) => {
        //console.log(resp)
        if (resp) {
          this.props.history.replace('/refinal')
        }
      })
  }

  render() {
    let { classes } = this.props;
    return (
      <div>
        <AlertDiv
          back={orange[100]}
          border={orange[400]}
        >
          <Typography
            variant="h5"
          >
            Your form fill up complete.
            </Typography>

          <br />

          <Typography
            variant="h6"
            gutterBottom
          >
            Your Application Form No is: <Chip color="primary" label={this.props.form_id} />
          </Typography>
          {/* <Typography>
            Please visit the college cash counter for Registration Fees payment.
            </Typography>
          <Typography>
            After your payment is done, you can print the payment receipt and application form for further proceedings.
            </Typography> */}
          <br />

          {/* <Typography>
            Please refresh if the payment is done.
            </Typography> */}
        </AlertDiv>
      </div>
    )
  }
}

export default withStyles(styles)(FinalSubmitForm)