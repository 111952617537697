import React, { Component } from 'react'
import { withStyles, Stepper, Step, StepButton } from '@material-ui/core';
import FormOne from './FormOne';
import FormTwo from './FormTwo';
import { PostData, GetData, PutData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FormThree from './FormThree';
import EducationalForm from './EducationalForm';
import Notifications, { notify } from 'react-notify-toast';
import PaymentGo from './PaymentGo';
import ViewForm from '../View/ViewForm';
import FinalSubmitForm from '../View/FinalSubmitForm';
import Loader from './Loader';
import MyAdmissionLanding from '../Admission/MyAdmissionLanding';

const styles = theme => ({
    step: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
})

const steps = [
    {
        label: 'Personal Details'
    },
    {
        label: 'Educational Details'
    },
    {
        label: 'Document Upload'
    },
    {
        label: 'Final Submit'
    }
]

class FormsLanding extends Component {
    state = {
        active: 0,
        email: '',
        course: '',
        form_id: '',
        course_type: '',
        stream: '',
        semester: '',
        data: [],
        completed: [],
        edit: false,
        edit_id: '',
        isLoaded: false,
        isSubmitting: false,
        hs:false
    }

    componentDidMount() {
        console.log(this.props.user.role)
        this.getInitialData()
    }


    getInitialData = () => {
        GetData(`/${this.props.user.email}/admissionform/getactivestudentsingle`)
            .then((resp) => {
                //console.log(resp)
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp,
                        course: resp[0].course,
                        form_id: resp[0].form_id,
                        course_type: resp[0].course_type,
                        semester: resp[0].semester,
                        stream: resp[0].stream,
                        email: resp[0].email,
                        edit: true,
                        edit_id: resp[0].id,
                        active: resp[0].active_step,
                        isLoaded: true
                    })
                } else {
            // for readmission pull data

                    if(this.props.user.role === 'RESTUDENT' && this.state.active === 0){
                        GetData(`/${this.props.user.apikey}/${this.props.user.email}/getstudentprofile`)
                        .then((resp) => {
                            //console.log(resp)
                            if(Array.isArray(resp) && resp.length > 0){
                                this.setState({
                                    data: resp,
                                    subjects: resp[0].subjects,
                                    hs: false,
                                    isLoaded: true,
                                    active: 0
                                })
                            }else{
                                this.setState({
                                    hs: true,
                                    isLoaded: true,
                                    active: 0
                                })
                            }
                        })
                    }

                    
                }

            })


            
    }

    onSetData = (data) => {
        
        if (!this.state.edit) {
            this.setState({isSubmitting: true})
            PostData(`/1/admissionform/addstudent`, data)
                .then((resp) => {
                    this.setState({isSubmitting: false})
                    if (resp.status === true) {

                        //place this step to completed

                        this.getInitialData();
                        // confirmAlert({
                        //     title: 'Successful',
                        //     message: resp.message,
                        //     buttons: [
                        //         {
                        //             label: 'Yes',
                        //             /* onClick: () => this.proceedToNext() */
                        //         },
                        //         {
                        //             label: 'No'
                        //         }
                        //     ]
                        // });
                    } else {
                        confirmAlert({
                            title: 'Failed!!',
                            message: resp.message,
                            buttons: [
                                {
                                    label: 'Reload',
                                    onClick: () => window.location.reload()
                                }
                            ]
                        });
                    }
                }).catch((err) => {
                    this.setState({isSubmitting: false})
                })

        } else {
            this.setState({isSubmitting: true})
            PutData(`/${this.state.edit_id}/1/admissionform/updatestudent`, data)
                .then((resp) => {
                    this.setState({isSubmitting: false})
                    if (resp.status === true) {

                        //place this step to completed

                        this.getInitialData();
                        // confirmAlert({
                        //     title: 'Successful',
                        //     message: resp.message,
                        //     buttons: [
                        //         {
                        //             label: 'Yes',
                        //             /* onClick: () => this.proceedToNext() */
                        //         },
                        //         {
                        //             label: 'No'
                        //         }
                        //     ]
                        // });
                    } else {
                        confirmAlert({
                            title: 'Failed!!',
                            message: resp.message,
                            buttons: [
                                {
                                    label: 'Reload',
                                    //onClick: () => window.location.reload()
                                }
                            ]
                        });
                    }
                }).catch((err) => {
                    this.setState({isSubmitting: false})
                })
        }
    }


    onSetOneData = (data) => {

        if (!this.state.edit) {
            this.setState({isSubmitting: true})
            PostData(`/2/admissionform/addstudent`, data)
                .then((resp) => {
                    this.setState({isSubmitting: false})
                    if (resp.status === true) {

                        //place this step to completed

                        this.getInitialData();
                        // confirmAlert({
                        //     title: 'Successful',
                        //     message: resp.message,
                        //     buttons: [
                        //         {
                        //             label: 'Yes',
                        //             /* onClick: () => this.proceedToNext() */
                        //         },
                        //         {
                        //             label: 'No'
                        //         }
                        //     ]
                        // });
                    } else {
                        confirmAlert({
                            title: 'Failed!!',
                            message: resp.message,
                            buttons: [
                                {
                                    label: 'Reload',
                                    onClick: () => window.location.reload()
                                }
                            ]
                        });
                    }
                })
                .catch((err) => {
                    this.setState({isSubmitting: false})
                })

        } else {
            this.setState({isSubmitting: true})
            PutData(`/${this.state.edit_id}/2/admissionform/updatestudent`, data)
                .then((resp) => {
                    this.setState({isSubmitting: false})
                    if (resp.status === true) {

                        //place this step to completed

                        this.getInitialData();
                        // confirmAlert({
                        //     title: 'Successful',
                        //     message: resp.message,
                        //     buttons: [
                        //         {
                        //             label: 'Yes',
                        //             /* onClick: () => this.proceedToNext() */
                        //         },
                        //         {
                        //             label: 'No'
                        //         }
                        //     ]
                        // });
                    } else {
                        confirmAlert({
                            title: 'Failed!!',
                            message: resp.message,
                            buttons: [
                                {
                                    label: 'Reload',
                                    onClick: () => window.location.reload()
                                }
                            ]
                        });
                    }
                })
                .catch((err) => {
                    this.setState({isSubmitting: false})
                })
        }
    }

    proceedToNext = () => {
        //get the next step
        let next_step = 0;
        if (this.state.active === 0) {
            next_step = 1;
        } else if (this.state.active === 1) {
            next_step = 2;
        } else if (this.state.active === 2) {
            next_step = 3;
        }

        this.setState({
            active: next_step
        })
    }

    proceedToBack = () => {
        //get the next step
        let next_step = 0;
        if (this.state.active === 0) {
            next_step = 0;
        } else if (this.state.active === 1) {
            next_step = 0;
        } else if (this.state.active === 2) {
            next_step = 1;
        }
        //newly added
        else if (this.state.active === 3) {
            next_step = 2;
        }
        

        this.setState({
            active: next_step
        })
    }

    setSecondData = (data) => {
        console.log(data)
        this.setState({isSubmitting: true})
        PostData(`/3/admissionform/addstudent`, data)
            .then((resp) => {
                this.setState({isSubmitting: false})
                if (resp.status === true) {
                    //newly addd
                    window.location.reload();
                    //this.proceedToNext();
                    // confirmAlert({
                    //     title: 'Successful',
                    //     message: resp.message,
                    //     buttons: [
                    //         {
                    //             label: 'Yes',
                    //             onClick: () => this.proceedToNext()
                    //         },
                    //         {
                    //             label: 'No'
                    //         }
                    //     ]
                    // });
                } else {
                    confirmAlert({
                        title: 'Failed!!',
                        message: resp.message,
                        buttons: [
                            {
                                label: 'Reload',
                                onClick: () => window.location.reload()
                            }
                        ]
                    });
                }
            }).catch((err) => {
                this.setState({isSubmitting: false})
            })
    }

    gotToStep = (step) => {
        this.setState({
            active: step
        })
    }

    gotToPayment = () => {
       GetData(`/${this.state.email}/admissionform/gotopayment`)
            .then((resp) => {
                if (resp.status) {
                    this.setState({
                        active: 4
                    })
                } else {
                    confirmAlert({
                        title: 'Failed!!',
                        message: resp.message,
                        buttons: [
                            {
                                label: 'Reload',
                                onClick: () => window.location.reload()
                            }
                        ]
                    });
                    
                }
            }) 

        


    }

    render() {
        let { classes } = this.props;
        let i = [];

        if (this.state.active === 0) {
            i = <FormOne
                history={this.props.history}
                user={this.props.user}
                setData={this.onSetData.bind(this)}
                data={this.state.data}
                edit={this.state.edit}
                next={this.proceedToNext}
                back={this.proceedToBack}
            />
        }
        else if (this.state.active === 1) {
            i = <EducationalForm
                history={this.props.history}
                user={this.props.user}
                form_id={this.state.form_id}
                setData={this.onSetOneData.bind(this)}
                data={this.state.data}
                edit={this.state.edit}
                next={this.proceedToNext}
                back={this.proceedToBack}

            />
        } else if (this.state.active === 2) {
            i = <FormTwo
                stream={this.state.stream}
                course={this.state.course}
                course_type={this.state.course_type}
                email={this.state.email}
                history={this.props.history}
                user={this.props.user}
                next={this.proceedToNext}
                back={this.proceedToBack}
                setData={this.setSecondData.bind(this)}
                data={this.state.data}
                edit={this.state.edit}
                semester={this.state.semester}
            />
        } else if (this.state.active === 3) {
            i = <FormThree
                stream={this.state.stream}
                course={this.state.course}
                email={this.state.email}
                history={this.props.history}
                user={this.props.user}
                next={this.proceedToNext}
                back={this.proceedToBack}
                gotToPayment={this.gotToPayment}
                semester={this.state.semester}
            />
        } else if (this.state.active === 4) {
            i = <FinalSubmitForm
                stream={this.state.stream}
                course={this.state.course}
                email={this.state.email}
                history={this.props.history}
                user={this.props.user}
                next={this.proceedToNext}
                back={this.proceedToBack}
                form_id={this.state.form_id}
                semester={this.state.semester}
            />
        }

        return (
            <div>

                <Notifications />

                {this.state.isSubmitting && <Loader />}

                <div className={classes.step}>

                    <Stepper nonLinear activeStep={this.state.active}>
                        {steps.map((el, index) =>

                            <Step key={index}>
                                <StepButton
                                /* onClick={() => this.gotToStep(index)} */

                                >
                                    {el.label}
                                </StepButton>
                            </Step>

                        )}
                    </Stepper>

                </div>

                <br />

                {this.state.isLoaded && this.state.data.length > 0 && <MyAdmissionLanding
                    user={this.props.user}
                    data={this.state.data[0]}
                    history={this.props.history}
                />}


                {this.state.isLoaded ? i : null}
            </div>
        )
    }
}

export default withStyles(styles)(FormsLanding)