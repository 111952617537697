import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileUpload from './FileUpload';

import allStates from './../../../../helper/IndianStates.json'

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})

const occupations = [
    "Govt. Service",
    "Non-Govt. Service",
    "Farmer",
    "Others"
]

class FormOne extends Component {

    state = {
        name: this.props.user.name,
       
        class_name: '',
        step:1,
        roll_no:'',
        admission_year:'',
        last_percentage:'',
        last_institution:'',
        expel_status:'',
        hostel_rules:'',
        hostel_boarder:'',
        hostel_damage:'',
        sports_status:'',
       
        phone: this.props.user.phone,
        email: this.props.user.email,
        
        gender: '',
        marrital_status: '',
        
        nationality: 'Indian',
       
        
        differently_abled: '',
        
        
    }

    componentDidMount() {

        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
                

            })
        }

    }



    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0]
                })
            }
        }

    }



    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

       if(e.target.name==='hostel_boarder' && e.target.value==='NO'){
        this.setState({
            hostel_dues:'NO'
        })
       }
    }

    onSubmit = (e) => {
        e.preventDefault();
       
        
        let d = this.state;
        console.log(this.state);
        this.props.setData(d)
        

        // confirmAlert({
        //     title: 'Confirm to submit',
        //     message: 'Are you sure to do this.',
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: () => this.props.setData(d)
        //         },
        //         {
        //             label: 'No'
        //         }
        //     ]
        // });

    }

   
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h5"
                    >
                        Application Form
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>
                    <Typography>
                    <span className="red-alert">Married student will not get admission in hostel.</span>
                    </Typography>
                    <br />

                    


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Personal Details
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                       Name of the Applicant <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        required
                                        readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Gender <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="gender"
                                        required
                                        value={this.state.gender}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Marital Status <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="marrital_status"
                                        required
                                        value={this.state.marrital_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Unmarried">Unmarried</option>
                                        <option value="Married">Married</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Nationality <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nationality"
                                        required
                                        value={this.state.nationality}
                                        onChange={this.onChange}
                                    />
                                        
                                </Grid>

                               
                            </Grid>

                        </CardContent>
                    </Card>
                    <br />


                    


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>



                        <CardContent>


                            <Grid container spacing={16}>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: blue[700]
                                        }}

                                    >
                                        Other Details
                                    </Typography>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Class to which admitted <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="class_name"
                                        required

                                        value={this.state.class_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Roll No
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="roll_no"

                                        
                                        value={this.state.roll_no}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Admission Year <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="admission_year"
                                        required

                                        value={this.state.admission_year}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Percentage of marks in last exam<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="last_percentage"

                                        required
                                        value={this.state.last_percentage}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                       Name of the Institution last attended<span className="red-alert">*</span>
                               </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="last_institution"

                                        required
                                        value={this.state.last_institution}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Were you expelled from college or hostel previously ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="expel_status"
                                        required
                                        value={this.state.expel_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Will you abide by hostel rules  ? <span className="red-alert">*</span>
                                </Typography>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="hostel_rules"
                                    required
                                    value={this.state.hostel_rules}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select One</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>


                                </select>

                            </Grid>

                            <Grid item xs={12} sm={12} lg={3} md={3}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Will you compensate any kind of damage to the hostel caused by you  ? <span className="red-alert">*</span>
                                </Typography>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="hostel_damage"
                                    required
                                    value={this.state.hostel_damage}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select One</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>


                                </select>

                            </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Were you a hostel boarder in previous year  ? <span className="red-alert">*</span>
                                </Typography>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="hostel_boarder"
                                    required
                                    value={this.state.hostel_boarder}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select One</option>
                                    <option value="NO">NO</option>
                                    <option value="YES">YES</option>


                                </select>

                            </Grid>

                      {this.state.hostel_boarder==='YES' && <Grid item xs={12} sm={12} lg={3} md={3}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Did you clear hostel dues within stipulated terms  ? <span className="red-alert">*</span>
                                </Typography>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="hostel_dues"
                                    required
                                    value={this.state.hostel_dues}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select One</option>
                                    <option value="NO">NO</option>
                                    <option value="YES">YES</option>


                                </select>

                            </Grid>}

                                
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Do you have any proficiency in games and sports ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="sports_status"
                                        required
                                        value={this.state.sports_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </Grid>

                            <Grid item xs={12} sm={12} lg={3} md={3}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Persion with Disability (PWD)  ? <span className="red-alert">*</span>
                                </Typography>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="differently_abled"
                                    required
                                    value={this.state.differently_abled}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select One</option>
                                    <option value="NO">NO</option>
                                    <option value="YES">YES</option>


                                </select>

                            </Grid>



                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>

                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormOne)