import React from 'react'
import AlertDiv from '../Forms/AlertDiv'
import { green, red, orange } from '@material-ui/core/colors'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

export default function MyAdmissionLanding({ user, data, history}) {
    return (
        <div>
            {data.is_verified === 1 && <div>
                
                <AlertDiv
                    border={green[300]}
                    back={green[50]}
                >
                  <VerifiedUserIcon style={{color: green[300]}} />  <strong>Successfully Verified</strong>, your application {data.form_id} is successfully verified at {data.verified_on}. 
                  {/* After the verification and merit list generation, if you are selected for the admission, click on the button below to accept the provisional admission offer and complete the formalities. For any queries feel free to contact office. */}
                  <br />
                  <br />

                  <Button
                    size="small"
                    style={{
                        backgroundColor: green[700],
                        color: 'white',
                        borderRadius: 0
                    }}
                    variant="contained"
                    //onClick={() => history.push(`/admission`)}
                    onClick={() => history.push(`/paynow`)}
                  >
                      Fees Payment
                  </Button>
                </AlertDiv>
            </div>}



            {data.is_verified === 2 && <div>
                <AlertDiv
                    border={red[300]}
                    back={red[50]}
                >
                  <ErrorIcon style={{color: red[300]}} />  <strong>Rejected</strong>, your application {data.form_id} got rejected. Reason: <b>{data.rejection_reason}</b>
                </AlertDiv>
            </div>}



            {data.is_verified === 3 && <div>
                <AlertDiv
                    border={orange[300]}
                    back={orange[50]}
                >
                  <WarningIcon style={{color: orange[300]}} />  <strong>Defective</strong>, your application {data.form_id} is found defective. Reason: <b>{data.rejection_reason}</b>
                </AlertDiv>
            </div>}
        </div>
    )
}
