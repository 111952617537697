import React, { Component } from "react";
import {
  withStyles,
  Button,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import { GetData } from "../../../api/service";
import ReactToPrint from "react-to-print";
import "./formprint.css";
import Barcode from "react-barcode";
import Loading from "../Forms/Loading";
import AlertDiv from "../Forms/AlertDiv";
import { orange } from "@material-ui/core/colors";
import MyAdmissionLanding from "../Admission/MyAdmissionLanding";

const styles = (theme) => ({});

class ViewForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoaded: false,
    };

    this.buttonRef = React.createRef();
  }

  getInitialData = () => {
    GetData(
      `/${this.props.user.email}/admissionform/getactivestudentsingle`
    ).then((resp) => {
      //console.log(resp[0])
      if (Array.isArray(resp) && resp.length > 0) {
        this.setState({
          data: resp[0],
          isLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    GetData(`/${this.props.user.email}/admissionform/hasreachedstep`).then(
      (resp) => {
        if (resp) {
          this.getInitialData();
        } else {
          this.props.history.replace("/rehome");
        }
      }
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <div>
        {this.state.isLoaded && (
          <MyAdmissionLanding
            user={this.props.user}
            data={this.state.data}
            history={this.props.history}
          />
        )}

        <div style={{ textAlign: "center" }}>
          <AlertDiv back={orange[100]} border={orange[400]}>
            You can print this form or can save as pdf. Click on the button
            below
          </AlertDiv>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print or Save
              </Button>
            )}
            ref={(el) => (this.buttonRef = el)}
            content={() => this.componentRef}
            pageStyle={{ margin: "10px" }}
            copyStyles={true}
          />
        </div>

        <br />
        <br />

        {this.state.isLoaded ? (
          <AdmissionForm
            ref={(el) => (this.componentRef = el)}
            {...this.state.data}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(ViewForm);

class AdmissionForm extends Component {
  goodSemester = (sem) => {
    if (sem == 1) {
      return "1st";
    } else if (sem == 2) {
      return "2nd";
    } else if (sem == 3) {
      return "3rd";
    } else {
      return `${sem}th`;
    }
  };
  render() {
    let total_marks = 0;
    let marks_secured = 0;
    return (
      <div className="admission-form">
        <table width="100%">
          <tbody>
            <tr>
              <td width="20%">
                <img
                  alt="logo"
                  src={"/images/logo.jpg"}
                  style={{ width: "100px" }}
                />
              </td>

              <td width="65%" align="center">
                <Typography variant="h5">
                  BHAWANIPUR ANCHALIK COLLEGE
                </Typography>
                <Typography variant="body2">
                  P.O-Bhawanipur, Dist-Barpeta, Assam, Pin-781352
                </Typography>
                <Typography variant="body2"></Typography>
                <Typography variant="body1">
                  <b>
                    Re-Admission Form - <b>{this.props.session_name}</b>
                  </b>
                </Typography>
              </td>

              <td width="15%" align="right">
                <Barcode
                  value={this.props.form_id}
                  height={40}
                  width={1.3}
                  margin={0}
                  displayValue={false}
                />
                <Typography variant="subtitle2">
                  Form No. : <b>{this.props.form_id}</b> <br />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <Grid container spacing={24}>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <table width="100%">
              <tbody>
                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Class to which admission is sought
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.course}-{this.props.stream}-{" "}
                      {this.goodSemester(this.props.semester)}{" "}
                      {this.props.course === "HS" ? `Year` : `Semester`}{" "}
                      {this.props.course === "TDC"
                        ? " - " + this.props.course_type
                        : null}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Student's Unique ID
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.unique_id}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Class Roll No
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.roll_no}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Examination Roll No
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.examination_roll_no}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Registration No
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.registration_no} of{" "}
                      {this.props.registration_year}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Student's Aadhar No
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.aadhar_no}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Student's PAN No
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">{this.props.pan_no}</Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                     Samarth Roll No
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.samarth_roll_no}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="100%">
              <tbody>
                <tr>
                  <td colSpan={4}>
                    {this.props.course === "HS" ? (
                      <table width="100%" className="table">
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <Typography
                                style={{
                                  fontWeight: "18px",
                                  fontWeight: 500,
                                }}
                              >
                                Subjects taken
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="body2">
                                Compulsory: {this.props.compulsory_1.label},{" "}
                                {this.props.compulsory_2.label}
                              </Typography>
                            </td>

                            <td>
                              <Typography variant="body2">
                                MIL: {this.props.mil.label}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <Grid container>
                                {this.props.optional_subjects.map(
                                  (el, index) => (
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      key={index}
                                    >
                                      <Typography variant="body2">
                                        {index + 1} : {el.label}
                                      </Typography>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null}

                    {this.props.course === "TDC" &&
                    this.props.semester == 3 &&
                    (this.props.stream == "ARTS" ||
                      this.props.stream == "SCIENCE") ? (
                      <table width="100%" className="table">
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                              >
                                Combination of Subject sougth for TDC 3rd
                                Semester
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                Core Course (Honours)
                              </Typography>

                              <Typography variant="body2" gutterBottom>
                                {this.props.major_1.label}
                              </Typography>

                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                Minor Course
                              </Typography>

                              <Typography variant="body2">
                                {this.props.major_2.label}
                              </Typography>

                              <Typography variant="body2">
                                {this.props.major_3.label}
                              </Typography>
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                Skill Enhancement Course
                              </Typography>

                              <Typography variant="body2">
                                {this.props.generic_1.label}
                              </Typography>
                            </td>

                            {this.props.course_type === "HONOURS" && (
                              <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Multi Disciplinary Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.generic_2.label}
                                </Typography>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    ) : null}

                    {(this.props.course === "TDC" &&
                    this.props.semester == 3 &&
                    this.props.stream == "COMMERCE") ? (
                      <table width="100%" className="table">
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                              >
                                Combination of Subject sougth for TDC 3rd
                                Semester
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                Core Course (Honours)
                              </Typography>

                              <Typography variant="body2">
                                {this.props.major_1.label}
                              </Typography>

                              <Typography variant="body2">
                                {this.props.compulsory_1.label}
                              </Typography>

                              <Typography variant="body2">
                                {this.props.compulsory_2.label}
                              </Typography>
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                Skill Enhancement Course
                              </Typography>

                              <Typography variant="body2">
                                {this.props.generic_1.label}
                              </Typography>
                            </td>

                            {this.props.course_type === "HONOURS" && (
                              <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Multi Disciplinary Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.generic_2.label}
                                </Typography>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    ) : null}

                    {this.props.course === "TDC" &&
                    this.props.stream == "ARTS" &&
                    this.props.semester == 5 ? (
                      <table width="100%" className="table">
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                              >
                                Combination of Subject sougth for TDC 5th
                                Semester
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            {this.props.course_type === "HONOURS" && (
                              <td>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Major
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.major_1.label}
                                </Typography>
                              </td>
                            )}

                            {this.props.course_type === "REGULAR" && (
                              <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  General Subject
                                </Typography>

                                <Typography variant="body2">
                                  1: {this.props.generic_1.label}
                                </Typography>
                                <Typography variant="body2">
                                  2: {this.props.generic_2.label}
                                </Typography>

                                <Typography variant="body2">
                                  2: {this.props.generic_3.label}
                                </Typography>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    ) : null}

                    {this.props.course === "TDC" &&
                    this.props.stream == "COMMERCE" &&
                    this.props.semester == 5 ? (
                      <table width="100%" className="table">
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                              >
                                Combination of Subject sougth for TDC 5th
                                Semester
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                Compulsory 1
                              </Typography>

                              <Typography variant="body2">
                                {this.props.compulsory_1.label}
                              </Typography>

                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                Compulsory 2
                              </Typography>

                              <Typography variant="body2">
                                {this.props.compulsory_2.label}
                              </Typography>
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                General Subject
                              </Typography>

                              <Typography variant="body2">
                                1: {this.props.generic_1.label}
                              </Typography>
                              <Typography variant="body2">
                                2: {this.props.generic_2.label}
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={3} align="right">
            <div className="passport-photo">
              <ImageViewer data={this.props.dp} />
            </div>
          </Grid>
        </Grid>

        {/* <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td colSpan="6">
                                <Typography
                                    variant="h6"
                                    align="center"
                                >
                                    FOR OFFICE USE
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    Date Of Admission
                                </Typography>
                            </td>

                            <td colSpan="4">
                                <Typography
                                    variant="body1"
                                >
                                    Class Admitted
                                </Typography>
                            </td>

                        </tr>

                        <tr>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    Registration Type
                                </Typography>
                            </td>

                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    New Student
                                </Typography>
                            </td>
                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Registered Student
                                </Typography>
                            </td>

                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Student Id
                                </Typography>
                            </td>
                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Roll No
                                </Typography>
                            </td>
                        </tr>



                        <tr>
                            <td colSpan="6" align="center">
                                <Typography
                                    variant="body1"
                                >
                                    Subjects Allotted
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>



                    </tbody>
                </table> */}

        <table width="100%" className="table">
          <tbody>
            <tr>
              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Applicant's Name
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">{this.props.name}</Typography>
              </td>
              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Phone No
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">{this.props.phone}</Typography>
              </td>
              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Email
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">{this.props.email}</Typography>
              </td>

              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  WhatsApp No
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">
                  {this.props.whatsapp_no}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Gender
                </Typography>
              </td>
              <td>
                <Typography variant="body2">{this.props.gender}</Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Date of Birth
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.good_date_of_birth}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Age as on 01-01-2020
                </Typography>
              </td>
              <td colSpan={3}>
                <Typography variant="body2">{this.props.age}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Caste
                </Typography>
              </td>
              <td>
                <Typography variant="body2">{this.props.caste}</Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Nationality
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.nationality}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Religion
                </Typography>
              </td>
              <td>
                <Typography variant="body2">{this.props.religion}</Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Maratial Status
                </Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">
                  {this.props.marrital_status}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Domicile State
                </Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">{this.props.state}</Typography>
              </td>

              <td colSpan={2}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Differently abled
                </Typography>
              </td>
              <td colSpan={4} width="30%">
                <Typography variant="body2">
                  {this.props.differently_abled}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Father/Guardian's Name
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_name}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Qualification
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_qualification}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Profession
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_profession}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Phone
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_contact}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Mother Name
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_name}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Qualification
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_qualification}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Profession
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_profession}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Phone
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_contact}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" className="table">
          <tbody>
            <tr>
              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Bank Details
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Bank Name: {this.props.bank_name}
                  <br />
                  Branch: {this.props.bank_branch}
                  <br />
                  A/C No: {this.props.bank_account}
                  <br />
                  IFSC: {this.props.bank_ifsc}
                  <br />
                  MICR Code: {this.props.micr_code}
                </Typography>
              </td>
              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Present Address
                </Typography>

                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Village/Town: {this.props.present_address.pre_village}
                  <br />
                  P.O: {this.props.present_address.pre_po}
                  <br />
                  Dist: {this.props.present_address.pre_dist}, State:{" "}
                  {this.props.present_address.pre_state}
                  <br />
                  Pin: {this.props.present_address.pre_pin}, Contact:{" "}
                  {this.props.present_address.pre_contact}
                </Typography>
              </td>

              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Permanent Address
                </Typography>

                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Village/Town: {this.props.permanent_address.per_village}
                  <br />
                  P.O: {this.props.permanent_address.per_po}
                  <br />
                  Dist: {this.props.permanent_address.per_dist}, State:{" "}
                  {this.props.permanent_address.per_state}
                  <br />
                  Pin: {this.props.permanent_address.per_pin}, Contact:{" "}
                  {this.props.permanent_address.per_contact}
                </Typography>
              </td>

              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Local Guardians' Details
                </Typography>

                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Name: {this.props.local_g}
                  <br />
                  Village/Town: {this.props.local_address.g_village}
                  <br />
                  P.O: {this.props.local_address.g_po}
                  <br />
                  Dist: {this.props.local_address.g_dist}, State:{" "}
                  {this.props.local_address.g_state}
                  <br />
                  Pin: {this.props.local_address.g_pin}, Contact:{" "}
                  {this.props.local_address.g_contact}
                  <br />
                  LAC: {this.props.permanent_address.per_lac}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table" width="100%">
          <tbody>
            <tr>
              <td width="20%">
                <Typography>Extra-Curricular Activity</Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">{this.props.extra_curr}</Typography>
              </td>

              <td width="20%">
                <Typography>Computer Course</Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">
                  {this.props.computer_course}
                </Typography>
              </td>
            </tr>

            <tr>
              <td width="20%">
                <Typography>Identification Mark (if any)</Typography>
              </td>
              <td width="30%" colSpan={3}>
                <Typography variant="body2">{this.props.id_mark}</Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table" width="100%">
          <tbody>
            <tr>
              <td colSpan="2">
                <Typography>Declaration from the Applicant</Typography>

                <Typography variant="body2">
                  &nbsp; &nbsp; &nbsp; &nbsp; I , {this.props.name} hereby
                  declare that I shall abide by the rules and regulations of the
                  College and shall be ready to accept any punishment which the
                  authority deems fit for me if there is any violation of the
                  rules by me. I do hereby declare that the statements made
                  above are true to the best of my knowledge and belief.
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "bottom",
                  }}
                >
                  <div>
                    <Typography variant="body2">Date:</Typography>
                  </div>
                  <div>
                    <div className="signature-photo">
                      <ImageViewer data={this.props.signature} />
                    </div>
                    <Typography variant="body2" style={{ float: "right" }}>
                      Full Signature of the Applicant
                    </Typography>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          {/* <tbody>
                        <tr>
                            <td colSpan="2">
                                <Typography>
                                    Declaration of the Parent/Guardian
                                </Typography>



                                <Typography
                                    variant="body2"
                                >
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    I,_________________________________ parent/guardian of the above named student, do hereby declare that I shall bear all educational expenses of my son/daughter/ward during his/her period of study. I have gone through the Prospectus of the College and declare that I shall be responsible for the conduct of my son/daughter/ward during his/her stay at the College. If my ward absent from regular classes for 15 days without information or if he/she fails to meet the minimum percentage of attendance as per University/Council notification then the decision of the college authority will be final.
                                </Typography>

                                <br />
                                <br />
                                <Typography
                                    variant="body2"
                                >
                                    Date:
                                    <Typography
                                        variant="body2"
                                        style={{ float: 'right' }}>
                                        Full Signature of the Parent / Guardian
                                </Typography>
                                </Typography>

                            </td>
                        </tr>

                    </tbody> */}
        </table>

        <table width="100%" className="table">
          <tbody>
            <tr>
              <td colSpan="6">&nbsp;</td>
            </tr>

            <tr>
              <td colSpan="6">
                <Typography variant="body1">
                  <b> Checked By </b>
                </Typography>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <Typography variant="body1">
                  <b>Selected</b>
                </Typography>
              </td>

              <td colSpan="2">
                <Typography variant="body1">
                  <b>Rejected</b>
                </Typography>
              </td>
              <td colSpan="2">
                <Typography variant="body1">
                  <b>Admitted</b>
                </Typography>
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <Typography variant="body1">
                  <b>Admission In Charge</b>
                </Typography>
              </td>
              <td colSpan="3">
                <Typography variant="body1">
                  <b>Principal</b>
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

class ImageViewer extends Component {
  state = {
    file_id: "",
    data: "",
    isLoaded: false,
  };

  __getFile = (file_id) => {
    GetData(`/${file_id}/getfilesingle`).then((resp) => {
      if (resp.hasOwnProperty("mime") && resp.hasOwnProperty("data")) {
        let file = "";
        if (
          resp.mime === "image/jpeg" ||
          resp.mime === "image/png" ||
          resp.mime === "image/jpg"
        ) {
          file = `data:${resp.mime};base64,${resp.data}`;
        }
        this.setState({
          data: file,
          isLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    let data = this.props.data;
    if (Array.isArray(data) && data.length > 0) {
      if (data[0].hasOwnProperty("file_id")) {
        this.__getFile(data[0].file_id);
      }
    }
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {this.state.isLoaded ? (
          <img
            alt="sig-photo"
            src={this.state.data}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}
